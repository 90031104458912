import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { RosettaSelectorModule, RosettaTourModule } from '@shared/modules';
import { LoadingSpinnerModule } from '@shared/modules/loading-spinner/loading-spinner.module';
import { RosettaTableOptions } from '@shared/modules/rosetta-table/models/rosetta-table.model';
import { TransformAddSampleDialogComponent } from '@shared/modules/transform/components/transform-add-sample-dialog/transform-add-sample-dialog.component';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { TransformSelectorManagerService } from '@shared/modules/transform/services/selectors/transform-selector-manager.service';
import { RosettaTitleCasePipe } from '@shared/pipes';
import { AppActions } from '@store/.';
import { combineLatest, first, map, startWith } from 'rxjs';
import { RunStatusComponent } from '../../components/run-status/run-status.component';
import { TransformDataViewerComponent } from '../../components/transform-data-viewer/transform-data-viewer.component';
import { PipelineRunData, PipelineRunInfo, SampleRowState } from '../../models';
import { TestPackGridSelection } from '../../models/test-pack-grid-selection.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '../../models/transform-config.model';
import { TransformService } from '../../services/transform.service';
import { transformTourSteps } from '../../transform.tour';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    RosettaTitleCasePipe,
    LoadingSpinnerModule,
    RosettaSelectorModule,
    RunStatusComponent,
    TransformDataViewerComponent,
    RosettaTourModule,
    LetDirective,
    MatButtonModule,
  ],
  selector: 'app-transform-list',
  templateUrl: './transform-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'transform-list' },
})
export class TransformListComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _destroyRef: DestroyRef,
    public transformService: TransformService,
    public transformSelectorManager: TransformSelectorManagerService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {}

  readonly tourSteps = transformTourSteps;

  addActionDisabled = signal(true);
  options: RosettaTableOptions<transform.DataViewerRow> = {
    actions: [
      {
        tourAnchorId: 'add-sample',
        label: 'Add Samples',
        icon: 'add',
        $disabled: this.addActionDisabled.asReadonly(),
        onClick: () => this.openAddSampleDialog(),
      },
    ],
  };

  private _isTestPackSelectionValid$ =
    this.transformSelectorManager.testPackGridSelection$.pipe(
      map(selection => !!selection),
      startWith(false)
    );

  dataViewerId = `${this._transformConfig.type.toLowerCase()}-list`;

  showDataViewer$ = this.transformService.isSupported$.pipe(first());

  ngOnInit(): void {
    this.showDataViewer$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(isSupported => {
        if (isSupported) {
          this.transformSelectorManager.init();
        }
      });

    combineLatest([
      this._isTestPackSelectionValid$,
      this.transformService.runResultState$.pipe(startWith(null)),
    ])
      .pipe(
        map(
          ([testPackSelectionValid, runResultState]) =>
            !!testPackSelectionValid && this._isRunComplete(runResultState)
        )
      )
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(canAddSample => this.addActionDisabled.set(!canAddSample));
  }

  ngOnDestroy(): void {
    this.transformSelectorManager.cleanup();
  }

  onRowClicked(row: transform.DataViewerRow): void {
    if (row.sampleRowState === SampleRowState.Errored) {
      return;
    }

    this.transformService.goToTransformDetails(
      row.testPackGridSelection,
      row.sampleId
    );
  }

  onRowEvent(event: transform.DataViewerEvent): void {
    this.transformService.processTransformEvent(event).subscribe({
      next: () => {
        this._store.dispatch(
          AppActions.showBasicSuccessMsg({
            message: `${event.type} success on ${event.rows.length} sample${event.rows.length > 1 ? 's' : ''}`,
          })
        );
      },
      error: e => {
        this._store.dispatch(
          AppActions.showBasicErrorMsg({ message: e.message })
        );
      },
    });
  }

  openAddSampleDialog(): void {
    const selection = this.transformSelectorManager.getCurrentSelection();
    const component = TransformAddSampleDialogComponent;
    this._dialog
      .open<TransformAddSampleDialogComponent, TestPackGridSelection, boolean>(
        component,
        component.options(selection)
      )
      .afterClosed()
      .pipe(first())
      .subscribe(shouldRunOnClose => {
        if (shouldRunOnClose) {
          this.transformService.rerun();
        }
      });
  }

  private _isRunComplete(
    pipelineRunInfo: PipelineRunInfo<PipelineRunData> | null
  ): boolean {
    if (pipelineRunInfo === null) {
      return true;
    }

    if (
      pipelineRunInfo.result === null ||
      pipelineRunInfo.result.data === null
    ) {
      return false;
    }

    const { currentPipeline, totalPipelines } = pipelineRunInfo.result.details;
    return currentPipeline === totalPipelines;
  }
}
