import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

// TODO: refactor this component to a set of classes
@Component({
  standalone: true,
  selector: 'app-rosetta-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `<ng-content />`,
  styles: [
    `
      @use 'partials/box-shadow' as shadow;
      @use 'partials/variables' as var;

      .rosetta-menu {
        display: inline-block;
        border-radius: var.$border-radius;
        padding: 0.5rem 1rem;
        overflow: auto;
        box-sizing: border-box;

        &.rosetta-menu-shadow {
          @include shadow.box-shadow-medium();
        }
      }
    `,
  ],
  host: {
    class: 'rosetta-menu theme-bg-alt',
  },
})
export class RosettaMenuComponent {
  constructor(
    @Attribute('maxWidth') private _maxWidth: string,
    @Attribute('maxHeight') private _maxHeight: string,
    @Attribute('noShadow') public noShadow: any
  ) {}

  @HostBinding('style.max-width.px') maxWidthStyle = this._maxWidth;
  @HostBinding('style.max-height.px') maxHeightStyle = this._maxHeight;
  @HostBinding('class.rosetta-menu-shadow') classNoShadow = !this.noShadow;
}
