import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Signal,
  booleanAttribute,
  computed,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { HoverClassDirective } from '@shared/directives';
import { StopPropagationDirective } from '@shared/modules';
import { MoreOptionsMenuComponent } from '@shared/modules/more-options-menu/more-options-menu.component';
import { MoreOptionsMenuItem } from '@shared/modules/more-options-menu/more-options-menu.model';
import { NewSample, SampleState } from '../../models';

@Component({
  standalone: true,
  selector: 'app-new-sample-card',
  templateUrl: './new-sample-card.component.html',
  styleUrls: ['./new-sample-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    FontsModule,
    MatMenuModule,
    HoverClassDirective,
    MoreOptionsMenuComponent,
    StopPropagationDirective,
  ],
})
export class NewSampleCardComponent implements OnInit {
  @Input({ required: true }) sample!: NewSample;

  @Input({ transform: booleanAttribute })
  set selected(value: boolean) {
    this.isSelected.set(value);
  }

  @Output() delete = new EventEmitter<NewSample>();
  @Output() edit = new EventEmitter<NewSample>();

  @HostListener('click')
  onClick(): void {
    this.edit.emit(this.sample);
  }

  isEditing = false;
  isSelected = signal(false);
  disabled: Signal<boolean>;
  sampleAdded: Signal<boolean>;
  sampleErrored: Signal<boolean>;
  sampleInvalid: Signal<boolean>;
  statusIcon: Signal<IconProp | null>;
  statusText: Signal<string | null>;
  sampleIconClass: Signal<string>;
  backgroundClass: Signal<string>;
  hoverBackgroundClass: Signal<string>;

  actionMenuItems: MoreOptionsMenuItem[] = [
    {
      label: 'Delete',
      icon: 'trash',
      onClick: () => this.delete.emit(this.sample),
    },
  ];

  ngOnInit(): void {
    this.disabled = computed(() => {
      const status = this.sample.status();
      return status === SampleState.Saving || status === SampleState.Saved;
    });
    this.sampleAdded = computed(
      () => this.sample.status() === SampleState.Saved
    );
    this.sampleErrored = computed(
      () => this.sample.status() === SampleState.Errored
    );
    this.sampleInvalid = computed(
      () => this.sample.status() === SampleState.Invalid
    );

    this.statusIcon = computed(() => this._getStatusIcon());
    this.statusText = computed(() => this._getStatusText());

    this.sampleIconClass = computed(() =>
      this.sample.status() === SampleState.Saving ? '' : 'theme-color-primary'
    );
    this.hoverBackgroundClass = computed(() =>
      this.sampleErrored() ? 'error-bg-darker' : 'theme-bg-light'
    );
    this.backgroundClass = computed(() => this._getBackgroundClass());
  }

  onActionSelected(actionId: string): void {
    switch (actionId) {
      case 'delete':
        this.delete.emit(this.sample);
        return;
    }
  }

  private _getStatusIcon(): IconProp | null {
    if (this.sampleAdded()) {
      return 'circle-check';
    }
    if (this.sampleErrored()) {
      return 'circle-xmark';
    }
    return null;
  }

  private _getBackgroundClass(): string {
    if (this.sampleAdded()) {
      return 'success-bg';
    }
    if (this.sampleErrored()) {
      return 'error-bg';
    }
    if (this.isSelected()) {
      return 'theme-bg-light';
    }
    return 'theme-bg-lighter';
  }

  private _getStatusText(): string | null {
    if (this.sampleAdded()) {
      return 'Added';
    }
    if (this.sampleErrored()) {
      return 'Failed';
    }
    return null;
  }
}
