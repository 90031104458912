import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkspaceUrlPrefixOperator } from '@app/utils/operators/workspace-url-prefix.operator';
import { ApiCacheConfig, RosettaConfig } from '@configs';
import { CodeViewWithDiagnosticsDTO } from '@models/dto';
import { Store } from '@ngrx/store';
import { TransformType } from '@shared/modules/transform/models/transform-config.model';
import { Observable, Subject, forkJoin, of, switchMap } from 'rxjs';
import { CacheBuster, Cacheable } from 'ts-cacheable';
import { SampleDef, TestPackSampleId } from '../models';
import * as mapperDTO from '../models/mapper';
import { PipelineDef } from '../models/pipeline-def.model';
import { TestPackDef } from '../models/test-pack-def.model';
import {
  TestPackGridAggregateSelection,
  TestPackGridSelection,
} from '../models/test-pack-grid-selection.model';
import { TestPackGrid } from '../models/test-pack-grid.model';
import { TransformDTO } from '../models/transform-dto.model';
import { TransientSample } from '../models/transient-sample.model';
import { TransientTestPack } from '../models/transient-test-pack.model';
import { IngestApiService } from './ingest-api.service';

export const runPipelineBuster$ = new Subject<void>();

@Injectable()
export class TransformServerStorageService {
  constructor(
    private _ingestApi: IngestApiService,
    private _store: Store,
    private _http: HttpClient
  ) {}

  private _baseResourceUrl$ = this._store.pipe(
    WorkspaceUrlPrefixOperator(RosettaConfig.resourcePaths.pipeline)
  );

  @Cacheable(ApiCacheConfig)
  getPipelines(transformType: TransformType): Observable<PipelineDef[]> {
    return this._baseResourceUrl$.pipe(
      switchMap(url => {
        if (transformType === TransformType.Ingest) {
          return this._ingestApi.getPipelines();
        }
        return this._http.get<TransformDTO.PipelineDef[]>(
          `${url}/${transformType}/pipelines`
        );
      }),
      mapperDTO.fromPipelineDefDTO()
    );
  }

  @Cacheable(ApiCacheConfig)
  getSampleDef(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<SampleDef> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.SampleDef>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/${sampleId}`
        )
      ),
      mapperDTO.fromSampleDefDTO()
    );
  }

  @Cacheable(ApiCacheConfig)
  getUpstreamPipelines({
    pipelineDef,
    testPackDef,
  }: TestPackGridSelection): Observable<TestPackGridSelection[]> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.TestPackGridSelection[]>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/upstream`
        )
      ),
      mapperDTO.fromTestPackGridSelectionDTO()
    );
  }

  @Cacheable(ApiCacheConfig)
  getUpstreamPipelinesForSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<TestPackGridSelection[]> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.TestPackGridSelection[]>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/upstream/${sampleId}`
        )
      ),
      mapperDTO.fromTestPackGridSelectionDTO()
    );
  }

  @Cacheable(ApiCacheConfig)
  getDownstreamPipelines({
    pipelineDef,
    testPackDef,
  }: TestPackGridSelection): Observable<TestPackGridSelection[]> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.TestPackGridSelection[]>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/downstream`
        )
      ),
      mapperDTO.fromTestPackGridSelectionDTO()
    );
  }

  @Cacheable(ApiCacheConfig)
  getAggregatedPipelines(
    selection: TestPackGridSelection
  ): Observable<TestPackGridAggregateSelection> {
    return forkJoin({
      upstream: this.getUpstreamPipelines(selection),
      midstream: of(selection),
      downstream: this.getDownstreamPipelines(selection),
    });
  }

  @Cacheable(ApiCacheConfig)
  geConnectedDownstreamPipelines({
    pipelineDef,
    testPackDef,
  }: TestPackGridSelection): Observable<TestPackGridSelection[]> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.TestPackGridSelection[]>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/connected-downstream`
        )
      ),
      mapperDTO.fromTestPackGridSelectionDTO()
    );
  }

  @Cacheable(ApiCacheConfig)
  getTestPacks(
    transformType: TransformType,
    pipelineId: string
  ): Observable<TestPackDef[]> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.TestPackDef[]>(
          `${url}/${transformType}/${pipelineId}/test-packs`
        )
      ),
      mapperDTO.fromTestPackDefsDTO()
    );
  }

  @Cacheable({
    ...ApiCacheConfig,
    cacheBusterObserver: runPipelineBuster$,
  })
  runTransientTestPack(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    transientTestPack?: TransientTestPack
  ): Observable<TransientTestPack> {
    return this._baseResourceUrl$.pipe(
      switchMap(url => {
        return this._http.post<TransformDTO.TransientTestPack>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/run-transient-pipeline`,
          mapperDTO.toTransientTestPackDTO(transientTestPack)
        );
      }),
      mapperDTO.fromTransientTestPackDTO()
    );
  }

  @Cacheable({
    ...ApiCacheConfig,
    cacheBusterObserver: runPipelineBuster$,
  })
  runTransientSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string,
    transientSample?: TransientSample
  ): Observable<TransientSample> {
    return this._baseResourceUrl$.pipe(
      switchMap(url => {
        return this._http.post<TransformDTO.TransientSample>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/${sampleId}/run-transient-sample`,
          mapperDTO.toTransientSampleDTO(transientSample)
        );
      }),
      mapperDTO.fromTransientSampleDTO(testPackDef)
    );
  }

  @Cacheable(ApiCacheConfig)
  getSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<TransientSample> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.TransientSample>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/${sampleId}/input`
        )
      ),
      mapperDTO.fromTransientSampleDTO(testPackDef)
    );
  }

  @Cacheable({
    ...ApiCacheConfig,
    cacheBusterObserver: runPipelineBuster$,
  })
  runTestPack(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    transientTestPack?: TransientTestPack
  ): Observable<TestPackGrid> {
    return this._baseResourceUrl$.pipe(
      switchMap(url => {
        return this._http.post<TransformDTO.TestPackGrid>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/run`,
          mapperDTO.toTransientTestPackDTO(transientTestPack)
        );
      }),
      mapperDTO.fromTestPackGridDTO()
    );
  }

  @Cacheable({ ...ApiCacheConfig, cacheBusterObserver: runPipelineBuster$ })
  codeViewInput(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string,
    transientSample?: TransientSample
  ): Observable<CodeViewWithDiagnosticsDTO> {
    return this._baseResourceUrl$.pipe(
      switchMap(url => {
        return this._http.post<CodeViewWithDiagnosticsDTO>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/${sampleId}/code-view/input`,
          mapperDTO.toTransientSampleDTO(transientSample)
        );
      })
    );
  }

  @Cacheable({ ...ApiCacheConfig, cacheBusterObserver: runPipelineBuster$ })
  codeViewOutput(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string,
    transientSample?: TransientSample
  ): Observable<CodeViewWithDiagnosticsDTO> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.post<CodeViewWithDiagnosticsDTO>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/${sampleId}/code-view/output`,
          mapperDTO.toTransientSampleDTO(transientSample)
        )
      )
    );
  }

  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  updateSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleIds: string[]
  ): Observable<void> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.post<void>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/update-sample`,
          sampleIds
        )
      )
    );
  }

  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  revertSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleIds: string[]
  ): Observable<void> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.post<void>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/revert-sample`,
          sampleIds
        )
      )
    );
  }

  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  addSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    formData: FormData
  ): Observable<TestPackSampleId> {
    return this._baseResourceUrl$.pipe(
      switchMap(url => {
        return this._http.post<TransformDTO.TestPackSampleId>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/add-sample`,
          formData
        );
      }),
      mapperDTO.fromTestPackSampleIdDTO()
    );
  }

  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  deleteSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<void> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.delete<void>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/${sampleId}/delete-sample`
        )
      )
    );
  }

  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  deleteDownstreamSamples(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<void> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.delete<void>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/${testPackDef.id}/${sampleId}/delete-downstream-samples`
        )
      )
    );
  }

  validateSample(
    { pipelineDef }: TestPackGridSelection,
    formData: FormData
  ): Observable<void> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.post<void>(
          `${url}/${pipelineDef.transformType}/${pipelineDef.id}/validate-sample`,
          formData
        )
      )
    );
  }
}
