import { Inject, Injectable } from '@angular/core';
import { HEART_BEAT, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { rewriteProjectWorkspaceUrlPath } from '@core/interceptors/helper-interceptor';
import { NotificationService } from '@core/modules/snack-bar';
import { TaskMessagesService } from '@core/modules/task-messages/task-messages.service';
import { WebSocketService } from '@core/services/websocket.service';
import {
  DependencyMap,
  PanelStates,
  ServiceName,
  ServiceState,
  Task,
  TaskNotification,
  UiPanel,
  WorkspaceIdPanelStates,
  WorkspaceInfo,
  dependencyMap,
} from '@models';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/.';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  filter,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs';

type ServiceMap = Map<ServiceName, ServiceState>;

@Injectable()
export class PanelStateService {
  constructor(
    private _webSocketService: WebSocketService,
    private _notificationService: NotificationService,
    private _taskMessagesService: TaskMessagesService,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _websocketState$ = new BehaviorSubject<number>(WebSocket.CLOSED);

  private _workspaceSessionReady =
    new ReplaySubject<WorkspaceIdPanelStates | null>(1);
  private _serviceStates$ = new ReplaySubject<ServiceMap>(1);

  private _webSocket: WebSocket | null = null;

  get workspaceSessionReady$(): Observable<WorkspaceIdPanelStates> {
    return this._workspaceSessionReady.pipe(filter(isNotNull));
  }

  get panelState$(): Observable<PanelStates> {
    return this._workspaceSessionReady.pipe(
      filter(isNotNull),
      map(workspaceSessionReady => workspaceSessionReady.panelStates)
    );
  }

  get webSocketState(): Observable<number> {
    return this._websocketState$.asObservable();
  }

  isPanelReady(panel: UiPanel): Observable<boolean> {
    return this.panelState$.pipe(
      map(panelStates => panelStates && panelStates.isPanelReady(panel))
    );
  }

  isSocketState(state: number): Observable<boolean> {
    return this._websocketState$.pipe(
      map(socketState => socketState === state)
    );
  }

  start(info: WorkspaceInfo): void {
    const { id, urlPath } = info;
    this._workspaceSessionReady.next(
      new WorkspaceIdPanelStates(id.name, this._initPanelStates(dependencyMap))
    );

    if (this._webSocket) {
      throw new Error('[Rosetta Error] Previous Web socket not closed!');
    }

    const webSocket = this._webSocketService.create(
      rewriteProjectWorkspaceUrlPath(
        `${this._config.sockets.serverState}/${urlPath}`,
        info
      )
    );

    this._webSocket = webSocket;
    this._webSocket.onopen = this._onOpen.bind(this);
    this._webSocket.onmessage = this._onMessage.bind(this);
    this._webSocket.onclose = this._onClose.bind(this);
    this._webSocket.onerror = this._onError.bind(this);
  }

  close(): void {
    this._workspaceSessionReady.next(
      new WorkspaceIdPanelStates('', this._initPanelStates(dependencyMap))
    );

    if (this._webSocket && this._webSocket.readyState <= 1) {
      this._webSocket.close(1000);
      this._websocketState$.next(this._webSocket.readyState);
    }
  }

  send(method: Task | string, params?: any): void {
    const options = {
      jsonrpc: '2.0',
      method,
      ...(params ? { params: JSON.stringify(params) } : {}),
    };

    if (!this._webSocket) {
      return;
    }

    this._webSocket.send(JSON.stringify(options));
  }

  private _initPanelStates(panelDependencies: DependencyMap): PanelStates {
    return new PanelStates(
      new Map(
        Array.from(panelDependencies).map(
          ([key, value]: [string, string[]]) => [
            key,
            value.map((service: string) => new ServiceState(service, false)),
          ]
        )
      )
    );
  }

  private _onOpen(): void {
    this.send('InitStateServer');
    this._webSocket && this._websocketState$.next(this._webSocket.readyState);
  }

  private _onMessage(event: MessageEvent): void {
    if (typeof event.data === 'string') {
      if (event.data === HEART_BEAT) {
        return;
      }

      const json = JSON.parse(event.data);

      switch (json.method) {
        case 'ServiceState':
          const serviceState = ServiceState.create(json.params);
          this._updatePanelState(serviceState);
          this._updateServiceState(serviceState);
          break;
        case 'taskNotification':
          this._taskMessagesService.onTaskNotification(
            json.params as TaskNotification
          );
          break;
        case 'MultipleSessions':
          this._notificationService.showError({
            message: this._config.text.MultipleSessions,
          });
          break;
        default:
          throw new Error(
            'panel-state socket message unknown method ' + json.method
          );
      }
    }
  }

  private _updatePanelState(serviceState: ServiceState): void {
    this._store
      .select(WorkspaceSelectors.selectWorkspaceId)
      .pipe(
        first(),
        filter(isNotNull),
        switchMap(workspaceId =>
          this.panelState$.pipe(
            first(),
            tap(panelStates =>
              this._workspaceSessionReady.next(
                new WorkspaceIdPanelStates(
                  workspaceId.name,
                  panelStates.updatePanelStates(serviceState)
                )
              )
            )
          )
        )
      )
      .subscribe();
  }

  private _updateServiceState(serviceState: ServiceState): void {
    this._serviceStates$.pipe(first()).subscribe(currentServiceState => {
      this._serviceStates$.next({
        ...currentServiceState,
        [serviceState.service]: serviceState,
      });
    });
  }

  private _onClose(): void {
    if (this._webSocket) {
      this._websocketState$.next(this._webSocket.readyState);
      this._webSocket = null;
    }

    this._store.dispatch(WorkspaceActions.websocketClose());
    this._store.dispatch(AuthActions.checkUserIsAuthenticated());
  }

  private _onError(): void {
    if (this._webSocket) {
      this._websocketState$.next(this._webSocket.readyState);
    }

    this._store.dispatch(WorkspaceActions.websocketError());
  }
}
