<app-rosetta-dialog
  [options]="dialogOptions"
  [disableSuccessAction]="form.invalid || form.pending"
  [showSpinnerAndDisableAll]="isSubmitting$ | async"
  (successAction)="createNamespace()"
>
  <form [formGroup]="form">
    <app-inline-error class="mb-16" *ngIf="isFailed$ | async as errorMessage">
      {{ errorMessage }}
    </app-inline-error>

    <mat-form-field class="mb-16">
      <mat-label>Namespace</mat-label>
      <input
        #inputField
        matInput
        formControlName="namespace"
        cdkFocusInitial
        autocomplete="off"
        [errorStateMatcher]="errorMatcher"
        required
      />

      <mat-error *ngIf="form.controls.namespace.hasError('required')">
        Namespace is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.controls.namespace.hasError('pattern')">
        Namespace must be '.' separated with no spaces or special characters
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mb-16">
      <mat-label>File Type</mat-label>
      <mat-select formControlName="fileType" #fileTypeField>
        <mat-option
          *ngFor="let fileType of fileTypes"
          [value]="fileType.extension"
        >
          {{ fileType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="preview-field">
      <small class="color-50">Preview</small>
      <p class="mb-0">
        {{ namespace$ | async }}<strong>:{{ fileTypeField.value }}</strong>
      </p>
    </div>
  </form>
</app-rosetta-dialog>
