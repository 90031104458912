<div
  class="samples-list__header flex-space-between"
  *ngIf="samples?.length > 0"
>
  <small class="mb-8 color-50">
    <strong>{{ samples.length }}</strong> samples
  </small>
  <small>
    <button
      mat-button
      [disabled]="disabled || !canClearAll"
      (click)="deleteAll.emit()"
    >
      <fa-icon icon="trash" size="sm" />
      Clear All
    </button>
  </small>
</div>
<ng-scrollbar>
  <app-new-sample-card
    *ngFor="let sample of samples"
    [sample]="sample"
    [selected]="sample === selectedSample"
    (delete)="deleteSample.emit($event)"
    (edit)="editSample.emit($event)"
  />
</ng-scrollbar>
