import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { getDialogOptions } from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { WorkspaceApiService } from '@core/services';
import { LanguageServerService } from '@core/services/language-server.service';
import {
  ComponentState,
  ContributeWorkspaceRequest,
  DialogComponent,
} from '@models';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { first, map, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-contribute-dialog',
  templateUrl: './contribute-dialog.component.html',
  styleUrls: ['./contribute-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContributeDialogComponent
  implements DialogComponent, OnInit, OnDestroy
{
  constructor(
    private _workspaceServiceApi: WorkspaceApiService,
    private _notify: NotificationService,
    private _dialogRef: MatDialogRef<ContributeDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _store: Store,
    private _languageServerService: LanguageServerService
  ) {}

  readonly maxSummaryLength = 50;

  form!: UntypedFormGroup;
  state = new ComponentState();

  private _sub = new Subscription();
  modifiedWorkspaceItems$ = this._store.select(
    WorkspaceSelectors.selectedModifiedWorkspaceItems
  );

  selectedNode$ = this._store.select(
    WorkspaceSelectors.selectCurrentWorkspaceItemUri
  );

  private _previousDiffEditor?: monaco.editor.IStandaloneDiffEditor;
  private _previousDiffNav?: monaco.editor.IDiffNavigator;

  static options(): MatDialogConfig {
    return getDialogOptions('max');
  }

  ngOnInit(): void {
    if (this._languageServerService.diffEditor) {
      this._previousDiffEditor = this._languageServerService.diffEditor;
      this._languageServerService.diffEditor = undefined;
    }

    if (this._languageServerService.diffNav) {
      this._previousDiffNav = this._languageServerService.diffNav;
      this._languageServerService.diffNav = undefined;
    }

    this.form = this._fb.group({
      summary: [
        '',
        [Validators.required, Validators.maxLength(this.maxSummaryLength)],
      ],
      description: ['', Validators.required],
    });

    this._sub.add(
      this.state.onChange.subscribe(state => {
        if (state === 'loading') {
          this.form.disable();
        } else {
          this.form.enable();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    if (this._previousDiffEditor && this._previousDiffNav) {
      this._languageServerService.diffEditor = this._previousDiffEditor;
      this._languageServerService.diffNav = this._previousDiffNav;
    }
  }

  onSubmit(): void {
    this.state.next('loading');
    this.modifiedWorkspaceItems$
      .pipe(
        first(),
        map(items => items.map(item => item.info.uri.replace('file://', ''))),
        switchMap(filePaths =>
          this._workspaceServiceApi.contributeWorkspace(
            this._createContributeRequest(filePaths)
          )
        )
      )
      .subscribe(
        () => this._successHandler(),
        error => this._errorHandler(error)
      );
  }

  /*
  - Load file nav
    Requirements:
    - Next/Prev change buttons
  */

  // nextFile() {
  //   if (this.isSubmitted) {
  //     return;
  //   }
  //   this.rosettaCoreService.diffNavigateSubject.next({
  //     direction: NavigateDirection.Next,
  //     modifiedItems: this.modifiedItemStates,
  //   });
  // }

  // prevFile() {
  //   if (this.isSubmitted) {
  //     return;
  //   }
  //   this.rosettaCoreService.diffNavigateSubject.next({
  //     direction: NavigateDirection.Previous,
  //     modifiedItems: this.modifiedItemStates,
  //   });
  // }

  private _successHandler(): void {
    this.state.next('success');
    this._notify.showSuccess({
      message: 'Your contribution has been sent for review.',
    });
    this._dialogRef.close();
  }

  private _errorHandler(error: ErrorEvent): void {
    this.state.next('error');
    this._notify.showError({ message: error.message });
  }

  private _createContributeRequest(
    filePaths: string[]
  ): ContributeWorkspaceRequest {
    return {
      summary: this.form.get('summary')?.value,
      commit: this.form.get('description')?.value,
      filePaths,
    };
  }
}
