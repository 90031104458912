import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MatDialogConfig,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { getDialogOptions } from '@configs';
import { DialogComponent } from '@models';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleRowAction } from '../../models/sample-action.enum';
import { CommonModule } from '@angular/common';
import { FontsModule } from '@app/fonts/fonts.module';
import { MatButtonModule } from '@angular/material/button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  standalone: true,
  imports: [CommonModule, FontsModule, MatDialogModule, MatButtonModule],
  selector: 'app-modify-expectation-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './modify-expectation-dialog.component.html',
})
export class ModifyExpectationDialogComponent
  implements DialogComponent, OnInit
{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: transform.DataViewerEvent
  ) {}

  expectationLabel = 'expectation';
  sampleLabel = 'sample';

  readonly sampleRowAction = SampleRowAction;
  readonly config = {
    [SampleRowAction.Revert]: {
      header: 'Discard Expectations for Sample',
      label: 'Discard',
      icon: 'arrow-rotate-left',
    },
    [SampleRowAction.Accept]: {
      header: 'Update Expectations for Sample',
      label: 'Update',
      icon: 'check',
    },
    [SampleRowAction.Delete]: {
      header: 'Delete Sample',
      label: 'Delete',
      icon: 'trash',
    },
    [SampleRowAction.Copy]: {
      header: 'Copy Sample',
      label: 'Copy',
      icon: ['far', 'copy'] as IconProp,
    },
  } as const;

  get itemLength(): number {
    return this._itemLength;
  }

  set itemLength(value: number) {
    this._itemLength = value;
    if (value > 1) {
      this.expectationLabel += 's';
      this.sampleLabel = `${value} samples`;
    }
  }

  private _itemLength!: number;

  static readonly options = (
    data: transform.DataViewerEvent
  ): MatDialogConfig =>
    getDialogOptions('sm', {
      data,
    });

  ngOnInit() {
    this.itemLength = this.data.rows.length;
  }
}
