import { Injectable } from '@angular/core';
import { TOUR_CONFIG } from '@app/config/tour.config';
import { StorageKey } from '@configs';
import { LocalStorageService } from '@core/services';
import { TourState } from 'ngx-ui-tour-core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { takeUntil } from 'rxjs';

export interface RosettaTourState<T = Set<string>> {
  tourVersion: number | null;
  seenStepIds: T;
}

export type RosettaStepOption = IMdStepOption & {
  anchorCheckOverrideId?: string;
};

@Injectable()
export class RosettaTourService {
  constructor(
    private _storage: LocalStorageService,
    private _tourService: TourService
  ) {}

  private get _disabled(): boolean {
    return window.__DISABLE_ROSETTA_TOUR__;
  }

  loadTourSteps(steps: RosettaStepOption[]): void {
    if (this._disabled) {
      return;
    }

    steps = this._removeSeenOrUnavailableSteps(steps);
    this._setupPopoverClass(steps);
    this._tourService.setDefaults({
      duplicateAnchorHandling: 'registerLast',
    });
    this._tourService.steps = [];
    this._tourService.initialize(steps, {
      enableBackdrop: false,
    });
  }

  start(): void {
    if (!this._disabled && !this.hasStarted()) {
      this._setup();
      this._tourService.start();
    }
  }

  stop(): void {
    this._tourService.steps = [];
    this._tourService.end();
  }

  hasStarted(): boolean {
    return this._tourService.getStatus() === TourState.ON;
  }

  private _getConfig(): RosettaTourState {
    const state = this._storage.getItem<RosettaTourState>(
      StorageKey.TourConfig
    );

    if (!state || state.tourVersion !== TOUR_CONFIG.version) {
      return { tourVersion: TOUR_CONFIG.version, seenStepIds: new Set() };
    }

    return {
      ...state,
      seenStepIds: new Set(state.seenStepIds),
    };
  }

  private _setConfig(state: RosettaTourState): void {
    this._storage.setItem(StorageKey.TourConfig, {
      ...state,
      seenStepIds: Array.from(state.seenStepIds),
    });
  }

  private _getId(step: RosettaStepOption): string {
    return step.stepId || step.anchorId || '';
  }

  private _setup(): void {
    this._tourService.stepHide$
      .pipe(takeUntil(this._tourService.end$))
      .subscribe(({ step }) => this._updateTourConfig([step]));
  }

  private _checkStepSeen(step: RosettaStepOption): boolean {
    return this._getConfig()?.seenStepIds.has(this._getId(step));
  }

  private _checkAnchorAvailable(step: RosettaStepOption): boolean {
    return (
      this._tourService.anchors[step.anchorCheckOverrideId || step.anchorId] !==
      undefined
    );
  }

  private _removeSeenOrUnavailableSteps(
    steps: RosettaStepOption[]
  ): RosettaStepOption[] {
    return steps.filter(
      step => this._checkAnchorAvailable(step) && !this._checkStepSeen(step)
    );
  }

  private _updateTourConfig(steps: RosettaStepOption[]): void {
    if (steps.length > 0) {
      const config = this._getConfig();
      steps.forEach(step => config.seenStepIds.add(this._getId(step)));
      this._setConfig(config);
    }
  }

  private _setupPopoverClass(steps: RosettaStepOption[]): void {
    steps.forEach((step: RosettaStepOption) => {
      let popoverClass = 'theme-bg';
      if (!!step.popoverClass) {
        popoverClass = `${popoverClass} ${step.popoverClass}`;
      }
      step.popoverClass = popoverClass;
    });
  }
}
