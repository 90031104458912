<ng-container *ngIf="sample">
  <app-error-message
    *ngIf="sample.errorReason"
    [error]="sample.errorReason"
    class="mb-16"
  />

  <mat-form-field appearance="outline" class="mb-16">
    <mat-label>Sample Name</mat-label>
    <input
      matInput
      [(ngModel)]="sample.name"
      [disabled]="!sample.waitingForUpload"
    />
  </mat-form-field>

  <app-rosetta-code-editor
    class="theme-border theme-bg-light"
    [model]="sample.getModel()"
    [editorOptions]="editorOptions"
    [disabled]="!sample.waitingForUpload"
    [(ngModel)]="sample.modelContent"
    (editorInit)="onEditorInit($event)"
  />
</ng-container>
