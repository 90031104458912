import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { getDialogOptions, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { SettingsActions, SettingsSelectors } from '@features/settings/store';
import { DialogComponent, LoadingState } from '@models';
import { Store } from '@ngrx/store';
import { mustMatch } from '@utils';
import { filter, first, merge, Subscription, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-update-password-dialog',
  templateUrl: './update-password-dialog.component.html',
  styleUrls: ['./update-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePasswordDialogComponent
  implements DialogComponent, OnInit, OnDestroy
{
  constructor(
    private _store: Store,
    private _fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<UpdatePasswordDialogComponent>,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  form!: UntypedFormGroup;
  isSaving$ = this._store.select(SettingsSelectors.isUpdatingPassword);
  isSavingState$ = this._store.select(
    SettingsSelectors.selectUpdatePasswordState
  );

  private _sub = new Subscription();

  static options(): MatDialogConfig {
    return getDialogOptions('sm', {
      disableClose: true,
    });
  }

  ngOnInit(): void {
    this._customCloseListeners();
    this._createForm();
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  onSave(): void {
    this._store.dispatch(
      SettingsActions.saveUpdatedPassword({ changedPassword: this.form.value })
    );
    this._waitForSave();
  }

  private _createForm(): void {
    this.form = this._fb.group(
      {
        oldPassword: ['', Validators.required],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(this._config.regex.password),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: mustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  private _customCloseListeners(): void {
    const takeWhile = this.isSaving$.pipe(filter(isSaving => !!isSaving));
    const mouseClick$ = this._dialogRef.backdropClick();
    const escapeClick$ = this._dialogRef
      .keydownEvents()
      .pipe(filter(event => event.key === 'Escape'));

    this._sub.add(
      merge(mouseClick$, escapeClick$)
        .pipe(takeUntil(takeWhile))
        .subscribe(() => this._dialogRef.close())
    );
  }

  private _waitForSave(): void {
    this._sub.add(
      this.isSavingState$
        .pipe(
          tap(() => this.form.disable({ onlySelf: true })),
          first(state => state !== LoadingState.LOADING)
        )
        .subscribe(state => {
          if (state === LoadingState.ERROR) {
            this.form.enable({ onlySelf: true });
          } else {
            this._dialogRef.close();
          }
        })
    );
  }
}
