import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { RosettaOverlayModule } from '@shared/modules';
import { RosettaMenuComponent } from '@shared/modules/rosetta-menu.component';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleCellState } from '@shared/modules/transform/models/sample-cell-state.enum';
import { EmptyStringPipe } from '@shared/pipes';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    RosettaOverlayModule,
    RosettaMenuComponent,
    EmptyStringPipe,
  ],
  selector: 'app-cell-data',
  templateUrl: './cell-data.component.html',
  styleUrls: ['./cell-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'cell-reg-report',
  },
})
export class CellDataComponent implements ICellRendererAngularComp {
  cell?: transform.DataViewerCell;
  previousCellValue?: string | null;
  previousCellLabel = 'Was';

  readonly sampleCellState = SampleCellState;

  agInit(
    params: ICellRendererParams<
      transform.DataViewerRow,
      transform.DataViewerCell
    >
  ): void {
    if (params.data?.errorMessage) {
      return;
    }

    this.cell = params.value;
    const isUpdated = this.cell?.cellState === SampleCellState.Updated;
    const { expectedBaseValue, expectedUpdatedValue } = this.cell || {};
    this.previousCellLabel = isUpdated ? 'Was' : 'Original';
    this.previousCellValue = isUpdated
      ? (expectedUpdatedValue ?? expectedBaseValue)
      : expectedBaseValue;
  }

  refresh(): boolean {
    return false;
  }
}
