<app-loading-banner [@inOutAnimation] *ngIf="isLoading$ | async" />

<as-split
  class="split-bottom-panel"
  appSplitSaveSize="core-panels"
  direction="vertical"
  [useTransition]="true"
  [gutterSize]="8"
  (dragEnd)="fireSplitUpdateEvent()"
  (transitionEnd)="fireSplitUpdateEvent()"
  [appRosettaTourStart]="tourSteps$ | async"
>
  <as-split-area [order]="1" [visible]="topPanel$ | async">
    <app-workspace-header />
    <app-workspace-content />
  </as-split-area>

  <as-split-area
    class="bottom-area theme-bg"
    [order]="2"
    [maxSize]="80"
    [minSize]="10"
    [visible]="bottomPanel$ | async"
  >
    <app-rosetta-panel />
    <div class="bottom-area__wrapper">
      <router-outlet />
    </div>
  </as-split-area>
</as-split>

<app-workspace-footer-nav appHeartbeat appIntegrateIntercom />
