import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  TrackByFunction,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontsModule } from '@app/fonts/fonts.module';
import { MoreOptionsMenuItem } from './more-options-menu.model';
import { RosettaTourModule } from '../rosetta-tour/rosetta-tour.module';

// TODO: Rename component to RosettaMenuComponent once existing component has been removed
@Component({
  standalone: true,
  selector: 'app-more-options-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    FontsModule,
    MatMenuModule,
    RosettaTourModule,
  ],
  templateUrl: './more-options-menu.component.html',
})
export class MoreOptionsMenuComponent {
  @Input({ required: true }) menuItems: MoreOptionsMenuItem[] = [];

  @ContentChild(TemplateRef) mainTemplate!: TemplateRef<any>;

  trackItemFn: TrackByFunction<MoreOptionsMenuItem> = (
    _index: number,
    item: MoreOptionsMenuItem
  ) => item.label;
}
