<ng-template #defaultButton>
  <button mat-icon-button>
    <fa-icon icon="ellipsis-v" />
  </button>
</ng-template>

<div *ngIf="menuItems?.length" [matMenuTriggerFor]="moreOptionsMenu">
  <ng-container [ngTemplateOutlet]="mainTemplate || defaultButton" />
</div>

<mat-menu #moreOptionsMenu="matMenu" xPosition="before">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      *ngFor="let item of menuItems; trackBy: trackItemFn"
      [tourAnchor]="
        item.tourAnchorId ? 'more-options.' + item.tourAnchorId : null
      "
      [ngClass]="item.buttonClasses"
      [disabled]="item?.$disabled && item.$disabled()"
      (click)="item.onClick()"
    >
      <fa-icon
        class="mr-8"
        *ngIf="!!item.icon"
        [ngClass]="item.iconClasses || 'theme-color-primary'"
        [icon]="item.icon"
      />
      <span>{{ item.label }}</span>
    </button>
  </ng-template>
</mat-menu>
