import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { getDialogOptions } from '@configs';
import { DialogComponent } from '@models';
import { ShowWhenWorkspaceDirective } from '@shared/directives';
import { RosettaDialogComponent, RosettaDialogOptions } from '@shared/modules';
import { getErrorCause, getErrorMessage } from '@utils';
import { catchError, of } from 'rxjs';
import { NewSample } from '../../models';
import { TransformStorageService } from '../../services/transform-storage.service';
import { StorageLocationSelectionComponent } from '../storage-location-selection/storage-location-selection.component';
import { TransformEditSampleComponent } from '../transform-edit-sample/transform-edit-sample.component';

@Component({
  selector: 'app-transform-copy-sample-dialog',
  standalone: true,
  imports: [
    CommonModule,
    RosettaDialogComponent,
    TransformEditSampleComponent,
    StorageLocationSelectionComponent,
    ShowWhenWorkspaceDirective,
  ],
  templateUrl: './transform-copy-sample-dialog.component.html',
  styles: [
    `
      app-transform-edit-sample {
        height: 50vh;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransformCopySampleDialogComponent
  implements OnInit, DialogComponent
{
  constructor(
    private _dialogRef: MatDialogRef<
      TransformCopySampleDialogComponent,
      boolean
    >,
    private _transformStorage: TransformStorageService,
    @Inject(MAT_DIALOG_DATA) public sample: NewSample
  ) {}

  readonly options: RosettaDialogOptions = {
    title: 'Copy Sample',
    successLabel: 'Copy',
    successIcon: ['far', 'copy'],
  };

  $addingSample = signal(false);
  storeOnServer = false;

  ngOnInit(): void {
    this.sample.name = `${this.sample.name} (Copy)`;
  }

  static options(data: NewSample): MatDialogConfig {
    return getDialogOptions('md', {
      disableClose: true,
      autoFocus: false,
      data,
    });
  }

  onSuccess(): void {
    this.$addingSample.set(true);
    this._transformStorage
      .addSample(
        this.sample.selection,
        this.sample.getTransientSample(),
        this.storeOnServer
      )
      .pipe(
        catchError(e => {
          this.$addingSample.set(false);
          this.sample.setError({
            title: `Failed to copy sample '${this.sample.name}'`,
            summary: getErrorMessage(e),
            details: getErrorCause(e),
          });
          return of(null);
        })
      )
      .subscribe(result => !!result && this.closeDialog(true));
  }

  closeDialog(rerun = false): void {
    this._dialogRef.close(rerun);
  }
}
