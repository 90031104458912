import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FontsModule } from '@app/fonts/fonts.module';
import { CollapsiblePanelModule } from '@shared/modules/collapsible-panel/collapsible-panel.module';
import { FileTreeLeafNodeComponent } from '@shared/modules/file-tree/file-tree-leaf-node/file-tree-leaf-node.component';
import { FileTreeNodeComponent } from '@shared/modules/file-tree/file-tree-node/file-tree-node.component';
import { FileTreeComponent } from '@shared/modules/file-tree/file-tree/file-tree.component';
import { HasAbilityPipe } from '@shared/pipes';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { StopPropagationDirective } from '../stop-propagation/stop-propagation.directive';

@NgModule({
  declarations: [
    FileTreeComponent,
    FileTreeNodeComponent,
    FileTreeLeafNodeComponent,
  ],
  imports: [
    CommonModule,
    FontsModule,

    // Third Party
    NgScrollbarModule,

    // UI Modules
    CollapsiblePanelModule,

    // Material
    MatTreeModule,
    MatButtonModule,
    MatTooltipModule,

    // Standalone
    HasAbilityPipe,
    StopPropagationDirective,
  ],
  exports: [FileTreeComponent],
})
export class FileTreeModule {}
