import { ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CODE_EDITOR_SAVE_INTERVAL } from '@configs';
import { ConfirmationService } from '@core/modules/confirmation/confirmation.service';
import { RollbarService } from '@core/modules/rollbar/rollbar.module';
import {
  DomainModelApiService,
  WorkspaceApiService,
  WorkspaceService,
} from '@core/services';
import { LanguageServerService } from '@core/services/language-server.service';
import { LoginApiService } from '@features/auth/login/services/login-api.service';
import { ContributeDialogComponent } from '@features/workspace/components/contribute-dialog/contribute-dialog.component';
import { DownloadWorkspaceDialogComponent } from '@features/workspace/components/download-workspace-dialog/download-workspace-dialog.component';
import { UpgradeDialogComponent } from '@features/workspace/components/upgrade-dialog/upgrade-dialog.component';
import { Task } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CreateNamespaceDialogComponent } from '@shared/dialogs';
import { ProjectLicensingTermsDialogComponent } from '@shared/modules/project-licensing-terms-dialog/project-licensing-terms-dialog.component';
import { AppActions, AuthActions, TaskActions } from '@store/.';
import * as RouterActions from '@store/router/router.actions';
import { domainModelToWorkspaceMapper } from '@store/workspace/reducers/workspace.reducers.helper';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import Rollbar from 'rollbar';
import {
  asyncScheduler,
  catchError,
  concatMap,
  debounceTime,
  defer,
  filter,
  first,
  from,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  toArray,
} from 'rxjs';
import * as WorkspaceActions from '../actions';
import {
  createDocumentIdMap,
  createWorkspaceMenuItems,
} from './workspace.effects.helper';

@Injectable()
export class WorkspaceEffects {
  constructor(
    private _actions$: Actions,
    private _domainModelApi: DomainModelApiService,
    private _languageService: LanguageServerService,
    private _workspaceService: WorkspaceService,
    private _api: WorkspaceApiService,
    private _loginApi: LoginApiService,
    private _confirm: ConfirmationService,
    private _dialog: MatDialog,
    private _store: Store,
    @Inject(RollbarService) @Optional() private _rollbar?: Rollbar
  ) {}

  getDomainModelsMapper$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(() => WorkspaceActions.getDomainModels())
    );
  });

  getDomainModels$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.getDomainModels),
      switchMap(() => this._domainModelApi.getAll()),
      map(domainModels =>
        WorkspaceActions.getDomainModelsSuccess({
          domainModels,
        })
      )
    );
  });

  loadLastWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.loadLastWorkspace),
      switchMap(() =>
        this._api.loadCurrentWorkspaceName().pipe(
          map(info => {
            if (!info) {
              throw new Error();
            }
            return info;
          }),
          mergeMap(info => [
            RouterActions.gotoWorkspace({ workspaceId: info.id }),
            WorkspaceActions.loadLastWorkspaceSuccess(),
          ]),
          catchError(() => of(WorkspaceActions.loadLastWorkspaceFailure()))
        )
      )
    );
  });

  saveWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspace),
      concatMap(() =>
        this._store.select(WorkspaceSelectors.selectWorkspaceItemsToSave).pipe(
          first(),
          concatMap(items => from(items)),
          mergeMap(item => this._api.saveWorkspaceItem(item)),
          toArray(),
          map(workspaceItems =>
            WorkspaceActions.saveWorkspaceSuccess({
              workspaceItems,
            })
          ),
          catchError(() => of(WorkspaceActions.saveWorkspaceFailure()))
        )
      )
    );
  });

  saveWorkspaceItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspaceItem),
      concatMap(({ uri }) =>
        this._store
          .select(WorkspaceSelectors.selectWorkspaceItemFromUri(uri))
          .pipe(
            first(),
            switchMap(item => this._api.saveWorkspaceItem(item)),
            map(workspaceItem =>
              WorkspaceActions.saveWorkspaceSuccess({
                workspaceItems: [workspaceItem],
              })
            ),
            catchError(() => of(WorkspaceActions.saveWorkspaceFailure()))
          )
      )
    );
  });

  saveWorkspaceFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspaceFailure),
      map(() =>
        AppActions.showBasicErrorMsg({
          message: 'We are unable to save your changes.', // TODO: Better error message
        })
      )
    );
  });

  confirmForkFile$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.confirmForkFile),
        switchMap(({ uri }) =>
          this._confirm
            .open({
              title: 'Override Namespace',
              message: `This namespace is part of a parent model.<br>Unlocking will override the namespace.`,
              confirmButtonText: 'Unlock',
              confirmButtonIcon: 'lock-open',
            })
            .afterClosed()
            .pipe(
              first(confirm => !!confirm),
              tap(() => this._languageService.addOverrideSyntax())
            )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  revertWorkspaceItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.revertWorkspaceItem),
      switchMap(({ uri, name }) =>
        this._confirm
          .open({
            title: 'Discard Changes',
            message: `Discard changes made to <strong>${name}</strong> content?`,
            confirmButtonIcon: 'arrow-rotate-left',
            confirmButtonText: 'Discard',
          })
          .afterClosed()
          .pipe(
            filter(confirm => !!confirm),
            map(() =>
              WorkspaceActions.revertWorkspaceItemConfirmed({
                uri,
              })
            )
          )
      )
    );
  });

  revertWorkspaceItemConfirmed$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.revertWorkspaceItemConfirmed),
        switchMap(({ uri }) =>
          this._store
            .select(WorkspaceSelectors.getWorkspaceItemByUri(uri))
            .pipe(
              first(),
              tap(
                item =>
                  item &&
                  this._languageService.updateModelContents(uri, item.contents)
              )
            )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  addWorkspaceItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.addWorkspaceItem),
      tap(({ workspaceItem }) =>
        this._languageService.createModels([workspaceItem])
      ),
      map(({ workspaceItem }) =>
        WorkspaceActions.selectWorkspaceItem({
          uri: workspaceItem.info.uri,
        })
      )
    );
  });

  updateWorkspaceItemContent$ = createEffect(
    () =>
      ({
        debounce = CODE_EDITOR_SAVE_INTERVAL,
        scheduler = asyncScheduler,
      } = {}) => {
        return this._actions$.pipe(
          ofType(WorkspaceActions.updateWorkspaceItemContent),
          debounceTime(debounce, scheduler),
          map(() => WorkspaceActions.saveWorkspace())
        );
      }
  );

  saveWorkspaceThenCloseWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspaceThenCloseWorkspace),
      mergeMap(({ unsetLastWorkspace, saveBeforeClose }) => [
        ...(saveBeforeClose ? [WorkspaceActions.saveWorkspace()] : []),
        WorkspaceActions.closeWorkspace({ unsetLastWorkspace }),
      ])
    );
  });

  closeWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.closeWorkspace),
      tap(() => this._workspaceService.close()),
      mergeMap(({ unsetLastWorkspace }) =>
        iif(
          () => unsetLastWorkspace,
          defer(() => this._api.setLast()),
          of(true)
        )
      ),
      mergeMap(() => [
        WorkspaceActions.clearServices(),
        WorkspaceActions.closeWorkspaceSuccess(),
      ])
    );
  });

  clearService$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.clearServices),
        tap(() => this._workspaceService.clearState())
      );
    },
    { dispatch: false }
  );

  loadProjects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.loadProjects),
      switchMap(() => this._loadProjects$())
    );
  });

  refreshProjectsCache$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.refreshProjectsCache),
      switchMap(() => this._loadProjects$({ refreshCache: true }))
    );
  });

  loadWorkspaces$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.loadWorkspaces),
      switchMap(() =>
        this._api
          .getWorkspaceMenuItem()
          .pipe(
            map(workspaceMenuItems =>
              WorkspaceActions.loadWorkspacesSuccess({ workspaceMenuItems })
            )
          )
      )
    );
  });

  deleteWorkspaces$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.deleteWorkspace),
      concatMap(({ workspaceId }) =>
        this._api.deleteWorkspace(workspaceId).pipe(
          map(() => WorkspaceActions.deleteWorkspaceSuccess({ workspaceId })),
          catchError(() =>
            of(WorkspaceActions.deleteWorkspaceFailure({ workspaceId }))
          )
        )
      )
    );
  });

  viewAcceptedModelDocuments$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.viewAcceptedModelDocuments),
      switchMap(({ modelId }) =>
        this._store
          .select(
            WorkspaceSelectors.getModelDocumentDialogData([modelId, true])
          )
          .pipe(
            first(),
            filter(modelDocumentData => modelDocumentData !== undefined),
            tap(modelDocumentData => {
              if (modelDocumentData && modelDocumentData.documents.length > 0) {
                this._dialog.open(
                  ProjectLicensingTermsDialogComponent,
                  ProjectLicensingTermsDialogComponent.options({
                    modelDocumentData,
                    disableClose: false,
                    readOnly: true,
                  })
                );
              }
            }),
            map(() => WorkspaceActions.checkModelDocumentsSuccess())
          )
      )
    );
  });

  closeProjectLicenseDialog$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.closeProjectLicenseDialog),
      switchMap(() =>
        this._store.select(WorkspaceSelectors.selectOpenDialogId).pipe(
          first(),
          tap(
            dialogId =>
              dialogId && this._dialog.getDialogById(dialogId)?.close()
          ),
          map(() => WorkspaceActions.closeProjectLicenseDialogSuccess())
        )
      )
    );
  });

  createNewWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.createNewWorkspace),
      concatMap(({ request }) =>
        this._api.createWorkspace(request).pipe(
          switchMap(workspace =>
            this._store
              .select(WorkspaceSelectors.getDomainModel(request.domainModelId))
              .pipe(
                first(),
                map(model => createWorkspaceMenuItems(workspace.info, model)),
                map(workspaceMenuItem =>
                  WorkspaceActions.createNewWorkspaceSuccess({
                    workspaceMenuItem,
                  })
                )
              )
          ),
          catchError(error =>
            of(WorkspaceActions.createNewWorkspaceFailure({ error }))
          )
        )
      )
    );
  });

  createNewWorkspaceSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.createNewWorkspaceSuccess),
      map(({ workspaceMenuItem }) => workspaceMenuItem.id),
      tap(() => this._dialog.closeAll()),
      mergeMap(workspaceId => [
        RouterActions.gotoWorkspace({ workspaceId }),
        WorkspaceActions.loadWorkspaces(),
      ])
    );
  });

  createNewWorkspaceFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.createNewWorkspaceFailure),
      tap(() => this._dialog.closeAll()),
      map(({ error }) =>
        AppActions.showBasicErrorMsg({
          message: 'Server error: ' + error.error.message,
        })
      )
    );
  });

  acceptedModelDocuments$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.acceptModelDocuments),
      switchMap(({ modelId, documents }) =>
        this._loginApi.acceptDocuments(createDocumentIdMap(documents)).pipe(
          map(() =>
            WorkspaceActions.acceptModelDocumentsSuccess({
              modelId,
              documents,
            })
          )
        )
      )
    );
  });

  acceptedModelDocumentsSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.acceptModelDocumentsSuccess),
      mergeMap(payload => [
        AuthActions.acceptModelDocumentsSuccess(payload),
        WorkspaceActions.closeProjectLicenseDialog(),
        WorkspaceActions.getDomainModels(),
      ])
    );
  });

  cancelAcceptModelDocuments$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.cancelAcceptModelDocuments),
      mergeMap(() => [
        WorkspaceActions.closeProjectLicenseDialog(),
        WorkspaceActions.navigateToWorkspaces(),
      ])
    );
  });

  workspaceAction$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.workspaceAction),
        map(({ action }) => {
          switch (action) {
            case 'contribute':
              return ContributeDialogComponent;
            case 'download':
              return DownloadWorkspaceDialogComponent;
            case 'upgrade':
              return UpgradeDialogComponent;
            case 'namespace':
              return CreateNamespaceDialogComponent;
            default:
              return undefined;
          }
        }),
        tap(dialog => {
          if (dialog) {
            this._dialog.open(
              dialog as ComponentType<unknown>,
              dialog.options()
            );
          }
        })
      );
    },
    { dispatch: false }
  );

  workspaceActionContribute$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.workspaceAction),
      filter(({ action }) => action === 'contribute'),
      map(() => WorkspaceActions.selectFirstWorkspaceItemWithChanges())
    );
  });

  gotoWorkspaces$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(
        WorkspaceActions.navigateToWorkspaces,
        WorkspaceActions.loadLastWorkspaceFailure
      ),
      map(() => RouterActions.gotoWorkspaceManager())
    );
  });

  workspaceExistsError$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.workspaceExistsError),
      mergeMap(({ workspaceName }) => [
        AppActions.showBasicErrorMsg({
          message: `Unable to open workspace "${workspaceName}".`,
        }),
        WorkspaceActions.navigateToWorkspaces(),
      ])
    );
  });

  websocketError$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.websocketError),
      tap(action => {
        if (action.rollbarError) {
          this._rollbar?.error(action.rollbarError);
        }
      }),
      map(() => WorkspaceActions.navigateToWorkspaces())
    );
  });

  invalidCompiledPojoError$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.invalidCompiledPojoError),
      switchMap(() =>
        this._store.select(WorkspaceSelectors.canRecompilePojo).pipe(first())
      ),
      filter(canRecompilePojo => !!canRecompilePojo),
      concatMap(() => [
        TaskActions.submitTask({
          data: {
            task: Task.PojoCompilation,
            payload: null,
          },
        }),
        WorkspaceActions.recompileInvalidPojo(),
      ])
    );
  });

  cleanupSessionState$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.cleanupSessionState),
      map(() => WorkspaceActions.cleanupSessionState())
    );
  });

  private _loadProjects$ = (
    { refreshCache }: { refreshCache: boolean } = { refreshCache: false }
  ) =>
    this._api.getModels(refreshCache).pipe(
      map(models => {
        const modelItems = domainModelToWorkspaceMapper(models);
        return WorkspaceActions.loadProjectsSuccess({ modelItems });
      })
    );
}
