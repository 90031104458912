import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { map, startWith } from 'rxjs';

@Component({
  selector: 'app-rosetta-tour-template',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <tour-step-template>
      <ng-template let-step="step">
        <div
          class="rosetta-tour-wrapper"
          [ngClass]="step?.popoverClass"
          appStopPropagation
        >
          <h4 [innerHTML]="step?.title"></h4>
          <p [innerHTML]="step?.content" class="color-50"></p>

          <div class="flex-row flex-row-center">
            <button
              mat-icon-button
              [disabled]="!tourService.hasPrev(step)"
              *ngIf="tourService.steps.length > 1"
              (click)="prev()"
            >
              <fa-icon icon="arrow-left" />
            </button>
            <span *ngIf="tourService.steps.length > 1">
              {{ currentIndex$ | async }}/{{ tourService.steps.length }}
            </span>
            <button
              mat-icon-button
              *ngIf="tourService.hasNext(step) && !step.nextOnAnchorClick"
              (click)="next()"
            >
              <fa-icon icon="arrow-right" />
            </button>
            <div class="spacer"></div>
            <button mat-stroked-button color="primary" (click)="end()">
              Got it
            </button>
          </div>
        </div>
      </ng-template>
    </tour-step-template>
  `,
  styles: [
    `
      .rosetta-tour-wrapper {
        padding: 1rem 1.5rem;
        max-width: 25vh;
      }

      h4 {
        font-size: 0.75rem;
        line-height: 1;
        font-weight: bold;
        margin: 0 0 1rem;
      }
    `,
  ],
})
export class RosettaTourTemplateComponent {
  constructor(public tourService: TourService) {}

  currentIndex$ = this.tourService.stepShow$.pipe(
    map(
      ({ step }) =>
        this.tourService.steps.findIndex(
          ({ anchorId }) => anchorId === step.anchorId
        ) + 1
    ),
    startWith(1)
  );

  prev(): void {
    this._markForCheck();
    this.tourService.prev();
  }

  next(): void {
    this._markForCheck();
    this.tourService.next();
  }

  end(): void {
    this._markForCheck();
    this.tourService.end();
  }

  private _markForCheck(): void {
    if (this.tourService.currentStep.anchorId) {
      // Workaround to trigger change detection on components with onPush enabled
      const anchor = this.tourService.anchors[
        this.tourService.currentStep.anchorId
      ] as any;
      const opener = anchor['opener'];

      if (opener) {
        opener.changeDetector.markForCheck();
      }
    }
  }
}
