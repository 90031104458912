import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StopPropagationDirective } from '@shared/modules';
import { getValueToDisplay } from '@shared/modules/rosetta-table/utils/rosetta-table-utils';
import { TransformDataViewerComponent } from '@shared/modules/transform/components/transform-data-viewer/transform-data-viewer.component';
import { SampleRowStatus } from '@shared/modules/transform/models';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleRowAction } from '@shared/modules/transform/models/sample-action.enum';
import { EmptyStringPipe } from '@shared/pipes';
import { WarningTriangleComponent } from '../warning-triangle/warning-triangle.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    MatButtonModule,
    MatTooltipModule,
    WarningTriangleComponent,
    EmptyStringPipe,
    StopPropagationDirective,
  ],
  selector: 'app-cell-sample-name',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './cell-sample-name.component.html',
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .cell-value {
        display: grid;
        grid-template-rows: 1fr;
        line-height: normal;
      }

      .remove-row {
        text-decoration: line-through;
        opacity: 0.25;
      }

      .small-button-wrapper {
        display: flex;
        gap: 0.5rem;
      }
    `,
  ],
})
export class CellSampleNameComponent implements ICellRendererAngularComp {
  rowData!: transform.DataViewerRow;
  cellValue!: string;
  rowError?: string;
  parentTable!: TransformDataViewerComponent;

  canUpdate = false;
  canDiscard = false;
  canDelete = false;
  canCopy = false;
  selectable = false;
  hideIconOnHover = false;

  isRowRemoved: boolean;
  addedToWorkspace: boolean;
  addIcon: IconProp;

  readonly sampleRowAction = SampleRowAction;
  readonly sampleRowStatus = SampleRowStatus;

  agInit(
    params: ICellRendererParams<
      transform.DataViewerRow,
      transform.DataViewerCell,
      transform.DataViewerContext
    >
  ): void {
    this.parentTable = params.context.parentTable;
    this.rowData = params.data;
    this.addIcon = params.data.storedOnClient ? 'circle' : 'circle-plus';
    this.isRowRemoved = params.data.sampleRowStatus === SampleRowStatus.Removed;
    this.addedToWorkspace =
      params.data.sampleRowStatus === SampleRowStatus.Added &&
      !params.data.storedOnClient;
    this.cellValue = getValueToDisplay(params);
    this._initData(params);
  }

  /*
  Cell Renderer Component Refresh Lifecycle
  - refresh() is called 0...n times (i.e. it may never be called, or called multiple times)
   */
  refresh(): boolean {
    return false;
  }

  onClick(type: SampleRowAction): void {
    this.parentTable.onActionBarEvent({
      type,
      rows: [this.rowData],
      testPackGridSelection:
        this.parentTable.context.dataViewer.testPackGridSelection,
      context: this.parentTable.context,
    });
  }

  private _initData(
    params: ICellRendererParams<transform.DataViewerRow>
  ): void {
    if (!params.data) {
      return;
    }

    if (params.data.selectable) {
      this.selectable = true;
      this.canUpdate = params.data.checkAction(SampleRowAction.Accept);
      this.canDiscard = params.data.checkAction(SampleRowAction.Revert);
      this.canDelete = params.data.checkAction(SampleRowAction.Delete);
    }

    this.canCopy = !params.data.errorMessage;
    this.rowError = params.data.errorMessage;

    this.hideIconOnHover = this.selectable || this.canCopy;
  }
}
