<header mat-dialog-title class="mb-24">
  <h2 class="spacer">{{ options.title }}</h2>
  <button
    mat-icon-button
    tabindex="-1"
    [disabled]="showSpinnerAndDisableAll"
    (click)="onClose()"
  >
    <fa-icon icon="times" />
  </button>
</header>

<mat-dialog-content>
  <ng-content />
</mat-dialog-content>

<mat-dialog-actions>
  <ng-content select="[rosetta-dialog-actions]" />

  <button mat-button (click)="onClose()" [disabled]="showSpinnerAndDisableAll">
    {{ options.closeLabel || 'Cancel' }}
  </button>
  <button
    *ngIf="!options.hideSuccessAction"
    mat-stroked-button
    (click)="onSuccess()"
    [disabled]="disableSuccessAction || showSpinnerAndDisableAll"
  >
    <fa-icon
      [icon]="options.successIcon || 'circle-check'"
      *appShowSpinner="!showSpinnerAndDisableAll; diameter: 18"
    />
    {{ options.successLabel || 'Ok' }}
  </button>
</mat-dialog-actions>
