import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  ErrorMessageComponent,
  RosettaCodeEditorComponent,
} from '@shared/modules';
import { NewSample } from '../../models';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RosettaCodeEditorComponent,
    MatFormFieldModule,
    MatInputModule,
    ErrorMessageComponent,
  ],
  selector: 'app-transform-edit-sample',
  templateUrl: './transform-edit-sample.component.html',
  styleUrls: ['./transform-edit-sample.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransformEditSampleComponent {
  @Input({ required: true }) sample: NewSample | null;

  readonly editorOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
    overviewRulerLanes: 10,
    minimap: {
      enabled: false,
    },
  };

  onEditorInit(editor: monaco.editor.IStandaloneCodeEditor): void {
    const textModel = editor.getModel();
    this.sample.textModel = textModel;
  }
}
