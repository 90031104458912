import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserDetailsDto } from '@features/auth/login';
import { Sizes } from '@shared/modules/project-logo/project-logo.component';
import { ProjectLogoModule } from '@shared/modules/project-logo/project-logo.module';
import { ModelPipe } from '@shared/pipes';

export interface ProjectCellParams<T = unknown> {
  getModelIds(data?: T): string[];
  maxCount?: number;
  size?: Sizes;
}

@Component({
  standalone: true,
  imports: [CommonModule, ProjectLogoModule, ModelPipe],
  selector: 'app-project-cell',
  templateUrl: './project-cell.component.html',
  styleUrls: ['./project-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCellComponent implements ICellRendererAngularComp {
  @Input() modelIds: string[] = [];
  @Input() size: Sizes = 'sm';
  @Input() maxCount = 6;

  agInit({
    getModelIds,
    data,
    size = 'sm',
  }: ICellRendererParams<UserDetailsDto> & ProjectCellParams) {
    this.size = size;
    this.modelIds = (getModelIds && getModelIds(data)) || [];
  }

  refresh(): boolean {
    return false;
  }
}
