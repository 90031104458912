import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogConfig } from '@angular/material/dialog';
import { RELEASE_NOTE_DELAY, getDialogOptions } from '@configs';
import { ReleaseNoteDialogService } from '@core/modules/release-notes/services/release-notes-dialog.service';
import { DialogComponent } from '@models';
import { Store } from '@ngrx/store';
import { InOutAnimation } from '@shared/animations';
import { ForceLinkTargetBlankDirective } from '@shared/directives';
import {
  LoadingSpinnerModule,
  RosettaDialogComponent,
  RosettaDialogOptions,
  TextBadgeModule,
} from '@shared/modules';
import { AppActions } from '@store/.';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { delay, first, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TextBadgeModule,
    LoadingSpinnerModule,
    NgScrollbarModule,
    RosettaDialogComponent,
    ForceLinkTargetBlankDirective,
  ],
  selector: 'app-release-notes-viewer-dialog',
  templateUrl: './release-notes-viewer-dialog.component.html',
  styleUrls: ['./release-notes-viewer-dialog.component.scss'],
  animations: [InOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseNotesViewerDialogComponent
  implements OnInit, DialogComponent
{
  constructor(
    private _releaseNoteDialogService: ReleaseNoteDialogService,
    private _store: Store,
    private _destroyRef: DestroyRef
  ) {}

  readonly dialogOptions: RosettaDialogOptions = {
    title: 'Release Notes',
    closeLabel: 'Close',
    closeDialogOnActionClick: true,
    hideSuccessAction: true,
  };

  releaseNotes$ = this._releaseNoteDialogService.releaseNotes$;

  ngOnInit(): void {
    this.releaseNotes$
      .pipe(
        first(),
        delay(RELEASE_NOTE_DELAY),
        tap(() => this._store.dispatch(AppActions.seenReleaseNote())),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  static options(): MatDialogConfig {
    return getDialogOptions('md');
  }
}
