import { RosettaStepOption } from '@shared/modules/rosetta-tour/rosetta-tour.service';

export const regulationTourSteps: RosettaStepOption[] = [
  {
    stepId: 'regulation-1',
    anchorId: 'new-regulation.supported-model',
    title: 'New Regulation Panel Available',
    content: 'Use the new panel to get a complete overview of your model.',
    isAsync: true,
  },
];
