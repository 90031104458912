import { ColDef } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  computed,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { FilterPipeFunc } from '@models';
import { LetDirective } from '@ngrx/component';
import { SlideInAnimation } from '@shared/animations';
import { RosettaOverlayModule } from '@shared/modules';
import { FilteredListComponent } from '@shared/modules/filtered-list/filtered-list.component';
import { RosettaTableComponent } from '@shared/modules/rosetta-table/rosetta-table.component';
import { ensureArray } from '@utils';
import { first } from 'rxjs';
import {
  MENU_ROW_HEIGHT,
  OVERLAY_WIDTH,
} from '../../models/rosetta-table.const';
import { RosettaTableOptions } from '../../models/rosetta-table.model';
import { COLUMN_TYPE_IGNORE } from '../../rosetta-table.helpers';

type ColDefExtended = ColDef<any> & { description?: string };

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    LetDirective,
    FilteredListComponent,
    RosettaOverlayModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
  ],
  selector: 'app-table-column-menu',
  templateUrl: './table-column-menu.component.html',
  styleUrls: ['./table-column-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SlideInAnimation],
  host: {
    class: 'table-column-menu',
  },
})
export class TableColumnMenuComponent implements OnInit {
  constructor(private _parent: RosettaTableComponent) {}

  @Input() disabled = false;

  canReorder = true;
  canHide = true;
  columnFilter = '';

  itemSize = signal(MENU_ROW_HEIGHT.Medium);
  overlayWidth = signal(OVERLAY_WIDTH.Medium);
  columns = signal<ColDefExtended[]>([]);
  editableCols = computed(() => this.columns().filter(col => !col.pinned));
  numberHiddenColumns = computed(
    () => this.editableCols().filter(col => col.hide).length
  );
  isAllSelected = computed(() => this.editableCols().every(col => !col.hide));
  isAnySelected = computed(() => {
    const hiddenCols = this.numberHiddenColumns();
    const editableCols = this.editableCols();
    return hiddenCols > 0 && hiddenCols < editableCols.length;
  });

  get columnMenuDescriptionDirection(): RosettaTableOptions['columnMenuDescriptionDirection'] {
    return this._parent.options.columnMenuDescriptionDirection;
  }

  filterFunc: FilterPipeFunc<ColDef> = search => {
    const regex = new RegExp(search, 'gi');
    return item => (item['headerName'] || '').match(regex);
  };

  ngOnInit(): void {
    this._parent.gridReady
      .pipe(first())
      .subscribe(() => this._initAfterGridReady());
  }

  columnToggle({ colId }: ColDef): void {
    const cols = this.columns();
    const col = cols.find(c => c.colId === colId);

    if (!this.canHide || !col) {
      return;
    }

    col.hide = !col.hide;

    this._setColumns(cols);
    this._parent.gridApi.updateGridOptions({ columnDefs: cols });
  }

  masterToggle(): void {
    const cols = this.columns();
    const editableCols = cols.filter(col => !col.pinned);
    const showHide =
      editableCols.length !== editableCols.filter(col => col.hide).length;
    editableCols.forEach(c => (c.hide = showHide));
    this._setColumns(cols);
    this._parent.gridApi.updateGridOptions({ columnDefs: cols });
  }

  private _initAfterGridReady(): void {
    this.canReorder = this._parent.options.canReorder || false;
    this.canHide = this._parent.options.canHideColumns || false;
    this._updateColumns();
    this._addEventListener();
  }

  private _addEventListener(): void {
    this._parent.gridApi.addEventListener(
      'newColumnsLoaded',
      this._updateColumns.bind(this)
    );
  }

  private _updateColumns(): void {
    this._setColumns(this._parent.gridApi.getColumnDefs() || []);
  }

  private _setColumns(cols: ColDef[]): void {
    const { columnMenuDescriptionGetter } = this._parent.options;
    let isLarge = false;

    const result = cols
      .filter(col => !ensureArray(col.type).includes(COLUMN_TYPE_IGNORE))
      .map(col => {
        const description =
          columnMenuDescriptionGetter && columnMenuDescriptionGetter(col);
        if (!isLarge && description) {
          isLarge = true;
          this._setOverlayToLarge();
        }
        return columnMenuDescriptionGetter ? { ...col, description } : col;
      });

    this.columns.set(result);
  }

  private _setOverlayToLarge() {
    this.overlayWidth.set(OVERLAY_WIDTH.Large);
    this.itemSize.set(MENU_ROW_HEIGHT.Large);
  }
}
