import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  booleanAttribute,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LoadingSpinnerModule } from '@shared/modules';
import { filter, merge, skipWhile } from 'rxjs';

export interface RosettaDialogOptions {
  title: string;
  successIcon?: IconProp;
  successLabel?: string;
  closeLabel?: string;
  closeDialogOnActionClick?: boolean;
  hideSuccessAction?: boolean;
}

@Component({
  selector: 'app-rosetta-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    MatDialogModule,
    MatButtonModule,
    LoadingSpinnerModule,
  ],
  templateUrl: './rosetta-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RosettaDialogComponent>,
    private _destroyRef: DestroyRef
  ) {}

  @Input({ required: true }) options: RosettaDialogOptions;
  @Input({ transform: booleanAttribute }) disableSuccessAction = false;
  @Input({ transform: booleanAttribute }) showSpinnerAndDisableAll = false;

  @Output() successAction = new EventEmitter<void>();
  @Output() closeAction = new EventEmitter<void>();

  ngOnInit(): void {
    merge(
      this.dialogRef.backdropClick(),
      this.dialogRef
        .keydownEvents()
        .pipe(filter(event => event.key === 'Escape'))
    )
      .pipe(
        skipWhile(() => this.showSpinnerAndDisableAll),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => this.onClose());
  }

  onClose(): void {
    this.closeAction.emit();
    this._closeDialog();
  }

  onSuccess(): void {
    this.successAction.emit();
    if (this.options.closeDialogOnActionClick) {
      this.onClose();
    }
  }

  private _closeDialog(): void {
    this.dialogRef.close();
  }
}
