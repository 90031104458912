import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { RosettaOverlayComponent } from './rosetta-overlay.component';

@Directive({
  standalone: true,
  selector: '[appRosettaOverlayHoverTarget]',
})
export class RosettaOverlayHoverTargetDirective {
  public elementRef = inject(ElementRef);
  public viewContainerRef = inject(ViewContainerRef);

  @Input('appRosettaOverlayHoverTarget')
  get overlayTarget(): RosettaOverlayComponent {
    return this._overlayTarget;
  }
  set overlayTarget(value: RosettaOverlayComponent) {
    if (value instanceof RosettaOverlayComponent) {
      this._overlayTarget = value;
      this._overlayTarget.overlayTarget = this;
    }
  }
  _overlayTarget!: RosettaOverlayComponent;

  @HostListener('mouseenter')
  showPopover(): void {
    this.overlayTarget?.open();
  }

  @HostListener('mouseleave')
  closePopover(): void {
    this.overlayTarget?.close();
  }
}
