<app-rosetta-dialog
  [options]="options"
  [showSpinnerAndDisableAll]="$addingSample()"
  [disableSuccessAction]="sample.name === ''"
  (successAction)="onSuccess()"
  (closeAction)="closeDialog()"
>
  <app-storage-location-selection
    [(storeOnServer)]="storeOnServer"
    *appShowWhenWorkspace="'read-write'"
    class="mb-16"
  />
  <app-transform-edit-sample [sample]="sample" />
</app-rosetta-dialog>
