import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FontsModule } from '@app/fonts/fonts.module';
import { IRosettaConfig, ROSETTA_CONFIG, getDialogOptions } from '@configs';
import { WorkspaceApiService } from '@core/services';
import {
  DialogComponent,
  FileType,
  FileTypeEnum,
  NamespaceForm,
  NamespaceFormRequest,
  WorkspaceItem,
  WorkspaceItemState,
} from '@models';
import { Store } from '@ngrx/store';
import {
  LoadingSpinnerModule,
  RosettaDialogComponent,
  RosettaDialogOptions,
} from '@shared/modules';
import { InlineErrorModule } from '@shared/modules/inline-error/inline-error.module';
import * as WorkspaceActions from '@store/workspace/actions';
import { createWorkspaceItemState } from '@store/workspace/reducers/workspace.reducers.helper';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { DirtyFieldErrorMatcher, fistEnumValue } from '@utils';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  filter,
  first,
  map,
  merge,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs';
import { FileTypeService } from './file-type.service';

const PREVIEW_PLACEHOLDER = '[ namespace ]';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    InlineErrorModule,
    LoadingSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    RosettaDialogComponent,
  ],
  selector: 'app-create-namespace-dialog',
  templateUrl: './create-namespace-dialog.component.html',
  styleUrls: ['./create-namespace-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileTypeService],
})
export class CreateNamespaceDialogComponent
  implements DialogComponent, OnInit, OnDestroy
{
  constructor(
    private _store: Store,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<ChangeDetectionStrategy>,
    private _workspaceApiService: WorkspaceApiService,
    private _fileTypeService: FileTypeService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  readonly dialogOptions: RosettaDialogOptions = {
    title: 'Create Namespace',
    successIcon: 'plus',
    successLabel: 'Create',
    closeLabel: 'Cancel',
  };

  form!: NamespaceForm;
  fileTypes!: FileType[];
  namespace$!: Observable<string>;

  errorMatcher = new DirtyFieldErrorMatcher();

  isSubmitting$ = new BehaviorSubject<boolean>(false);
  isFailed$ = new BehaviorSubject<string | null>(null);
  modelShortname$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceInfo)
    .pipe(
      first(),
      map(info => info.modelShortname.toLowerCase())
    );

  private _sub = new Subscription();

  static options(): MatDialogConfig {
    return getDialogOptions('sm', {
      disableClose: true,
    });
  }

  ngOnInit(): void {
    this.fileTypes = this._fileTypeService.fileType;
    this._customCloseListeners();
    this._initForm();
    this.namespace$ = this.form.valueChanges.pipe(
      startWith({}),
      switchMap(changes =>
        this.modelShortname$.pipe(
          map(shortname => this._getNamespace(changes, shortname))
        )
      )
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  createNamespace(): void {
    if (this.form.invalid) {
      return;
    }

    this.form.disable({ emitEvent: false });
    this.isSubmitting$.next(true);

    this._createNamespaceRequest()
      .pipe(
        switchMap(payload =>
          this._workspaceApiService.createNewNamespaceFile(payload)
        ),
        switchMap(item => this._createWorkspaceItemState(item))
      )
      .subscribe({
        next: workspaceItem => {
          this._store.dispatch(
            WorkspaceActions.addWorkspaceItem({ workspaceItem })
          );

          this.isSubmitting$.next(false);
          this._dialogRef.close();
        },
        error: err => {
          this.isFailed$.next(err.error.message);
          this.isSubmitting$.next(false);
          this.form.enable({ emitEvent: false });
          return err;
        },
      });
  }

  private _createWorkspaceItemState(
    item: WorkspaceItem
  ): Observable<WorkspaceItemState> {
    return this.modelShortname$.pipe(
      map(modelShortname => createWorkspaceItemState(item, modelShortname))
    );
  }

  private _createNamespaceRequest(): Observable<NamespaceFormRequest> {
    const namespace = this.form.get('namespace')?.value;
    const fileType = this.form.get('fileType')?.value;

    if (!namespace || !fileType) {
      throw new Error('');
    }

    return this.modelShortname$.pipe(
      map(modelShortname => ({
        namespace: namespace.replace(modelShortname + '.', ''),
        fileType,
      }))
    );
  }

  private _initForm(): void {
    this.form = this._fb.group({
      namespace: this._fb.control('', [
        Validators.required,
        Validators.pattern(this._config.regex.newNamespace),
      ]),
      fileType: this._fb.nonNullable.control(
        fistEnumValue(FileTypeEnum),
        Validators.required
      ),
    });

    this._sub.add(
      this.form.valueChanges.subscribe(() => this.isFailed$.next(null))
    );
  }

  private _customCloseListeners(): void {
    const mouseClick$ = this._dialogRef.backdropClick();
    const escapeClick$ = this._dialogRef
      .keydownEvents()
      .pipe(filter(event => event.key === 'Escape'));

    this._sub.add(
      merge(mouseClick$, escapeClick$)
        .pipe(takeWhile(() => this.isSubmitting$.value))
        .subscribe(() => this._dialogRef.close())
    );
  }

  private _getNamespace(
    changes: Partial<{ namespace: string | null; fileType: string }>,
    shortname: string
  ): string {
    const namespace = changes.namespace || PREVIEW_PLACEHOLDER;
    return namespace && namespace.startsWith(shortname + '.')
      ? namespace
      : `${shortname}.${namespace}`;
  }
}
