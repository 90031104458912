import { MatDialogConfig } from '@angular/material/dialog';

const DIALOG_SIZES = {
  xs: ['xs-dialog', { width: '280px' }],
  sm: ['sm-dialog', { width: '550px' }],
  md: ['md-dialog', { width: '750px' }],
  lg: ['lg-dialog', { width: 'max(90vw, 1000px)', maxWidth: 'none' }],
  'max-padding': [
    'max-dialog',
    { width: 'calc(100vw - 90px)', height: 'calc(100% - 90px)' },
  ],
  max: [
    'no-padding-dialog-container',
    {
      width: '100vw',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      hasBackdrop: false,
    },
  ],
} as const;

export const getDialogOptions = <T>(
  size: keyof typeof DIALOG_SIZES,
  overrides?: Partial<MatDialogConfig<T>>
): MatDialogConfig => {
  const [panelClass, sizeOptions] = DIALOG_SIZES[size];
  return {
    disableClose: false,
    autoFocus: true,
    closeOnNavigation: true,
    restoreFocus: false,
    panelClass,
    ...(sizeOptions ? sizeOptions : {}),
    ...overrides,
  };
};
