import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MONACO_LOADER,
  MonacoLoader,
} from '@features/workspace/modules/design/textual/services/monaco-loader';
import { defaultConfig } from './default.config';

export interface RosettaEditorModel {
  value: string;
  language?: string;
  uri?: monaco.Uri;
}

export interface RosettaEditorOptions {
  disposeModelOnClose?: boolean;
}

/*
This is based on ngx-monaco-editor-v2
https://github.com/miki995/ngx-monaco-editor-v2/blob/master/projects/editor/src/lib/editor.component.ts#L72
*/

@Component({
  standalone: true,
  selector: 'app-rosetta-code-editor',
  template: `<div #codeEditorContainer></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RosettaCodeEditorComponent),
      multi: true,
    },
  ],
  host: {
    class: 'rosetta-code-editor',
  },
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }

      div {
        height: 100%;
        overflow: hidden;
      }
    `,
  ],
})
export class RosettaCodeEditorComponent
  implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor
{
  constructor(@Inject(MONACO_LOADER) private _loader: MonacoLoader) {}

  @ViewChild('codeEditorContainer', { static: true })
  _editorContainer: ElementRef;

  @Input() model?: RosettaEditorModel;
  @Input() editorOptions: monaco.editor.IStandaloneEditorConstructionOptions =
    {};
  @Input() options: RosettaEditorOptions = {};

  @Output() editorInit =
    new EventEmitter<monaco.editor.IStandaloneCodeEditor>();

  private _editor?: monaco.editor.IStandaloneCodeEditor;
  private _textModel?: monaco.editor.ITextModel;
  private _value = '';

  /** The method to be called in order to update ngModel */
  private _onChange: (value: any) => void = () => {};
  private _onTouched: () => any = () => {};

  ngAfterViewInit(): void {
    this._loader.loadMonaco().then(() => {
      this._initEditor();
    });
  }

  ngOnChanges({ editorOptions, model }: SimpleChanges): void {
    if (editorOptions?.currentValue) {
      this._updateEditorOptions();
    }
    if (model?.currentValue) {
      this._updateModel();
    }
  }

  ngOnDestroy(): void {
    if (this.options?.disposeModelOnClose) {
      this._editor?.getModel()?.dispose();
    }
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this._value = value || '';
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this._editor && !this.editorOptions.model) {
        this._editor.setValue(this._value);
      }
    });
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this._editor?.updateOptions({ readOnly: isDisabled });
  }

  private _updateEditorOptions(): void {
    this.editorOptions = { ...defaultConfig, ...this.editorOptions };
    if (this._editor) {
      this._editor.dispose();
      this._initEditor();
    }
  }

  private _updateModel(): void {
    if (this._editor) {
      this._editor.dispose();
      this._initEditor();
    }
  }

  private _initEditor(): void {
    this._textModel = this._getModel();

    this._editor = monaco.editor.create(this._editorContainer.nativeElement, {
      ...this.editorOptions,
      model: this._textModel,
    });

    this._editor.onDidChangeModelContent(() => {
      const value = this._editor.getValue();

      this._onChange(value);
      this._value = value;
    });

    this._editor.onDidBlurEditorWidget(() => {
      this._onTouched();
    });

    this.editorInit.emit(this._editor);
  }

  private _getModel(): monaco.editor.ITextModel {
    if (!this.model) {
      return monaco.editor.createModel('');
    }

    return this.model.uri
      ? monaco.editor.getModel(this.model.uri)
      : monaco.editor.createModel(
          this.model.value,
          this.model.language,
          this.model.uri
        );
  }
}
