import { createAction, props } from '@ngrx/store';
import { CopyWorkspaceRequest, WorkspaceId } from '@models';

export const copyWorkspace = createAction(
  '[Support Page] Copy Workspace',
  props<{ payload: CopyWorkspaceRequest }>()
);

export const copyWorkspaceSuccess = createAction(
  '[Support Page] Copy Workspace Success'
);

export const copyWorkspaceError = createAction(
  '[Support Page] Copy Workspace Error',
  props<{ message: string }>()
);

export const downloadWorkspace = createAction(
  '[Support Page] Download Workspace Started',
  props<{ workspaceId: WorkspaceId }>()
);

export const downloadWorkspaceSuccess = createAction(
  '[Support Page] Download Workspace Success'
);

export const downloadWorkspaceError = createAction(
  '[Support Page] Download Workspace Failure',
  props<{ message: string }>()
);

export const uploadWorkspace = createAction(
  '[Support Page] Upload Workspace Started'
);

export const uploadWorkspaceSuccess = createAction(
  '[Support Page] Upload Workspace Success'
);

export const uploadWorkspaceError = createAction(
  '[Support Page] Upload Workspace Failure',
  props<{ message: string }>()
);

export const syncRoles = createAction('[Support Page] Sync Roles');

export const syncRolesSuccess = createAction(
  '[Support Page] Sync Roles Success'
);

export const syncRolesError = createAction(
  '[Support Page] Sync Roles Failure',
  props<{ message: string }>()
);

export const syncUser = createAction(
  '[Support Page] Sync User',
  props<{ userId: string }>()
);

export const syncUserSuccess = createAction('[Support Page] Sync User Success');

export const syncUserError = createAction(
  '[Support Page] Sync User Failure',
  props<{ message: string }>()
);
