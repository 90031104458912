import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  booleanAttribute,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RosettaOverlayModule } from '@shared/modules';
import { RosettaOverlayComponent } from '@shared/modules/rosetta-overlay/rosetta-overlay.component';
import { round } from '@utils';
import {
  GoogleChartComponent,
  GoogleChartInterface,
  Ng2GoogleChartsModule,
} from 'ng2-google-charts';
import {
  chartColours,
  pieChartFactory,
} from '../../configs/chart-options.factory';
import { DiagnosticSummary } from './diagnostic.models';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    Ng2GoogleChartsModule,
    RosettaOverlayModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-diagnostic-chart',
  templateUrl: './diagnostic-chart.component.html',
  styleUrls: ['./diagnostic-chart.component.scss'],
})
export class DiagnosticChartComponent {
  chart?: GoogleChartInterface;
  completeness?: number;

  @Input()
  title: string;

  @Input()
  icon?: IconProp;

  @Input()
  popover?: RosettaOverlayComponent;

  @Input({ transform: booleanAttribute }) set showExcluded(show: boolean) {
    this._showExcluded = show;

    if (this._isEmpty) {
      return;
    }

    this._updateChart();
  }

  @Input({ required: true }) set diagnosticSummary(
    diagnosticSummary: DiagnosticSummary
  ) {
    this._diagnosticsSummary = diagnosticSummary;

    if (this._isEmpty) {
      this.chart = undefined;
      this.completeness = undefined;
      return;
    }

    this._updateChart();
    this._updateCompleteness();
  }

  @ViewChild('chartTemplate')
  private _chartTemplate?: GoogleChartComponent;

  private _diagnosticsSummary: DiagnosticSummary;
  private _showExcluded?: boolean;

  private _updateChart(): void {
    if (!this.chart) {
      this.chart = pieChartFactory();
    }

    this.chart.dataTable = [];

    if (this._diagnosticsSummary.success >= 0) {
      this.chart.dataTable.push([
        'Successes',
        this._diagnosticsSummary.success,
      ]);
    }

    if (this._diagnosticsSummary.failure >= 0) {
      this.chart.dataTable.push(['Failures', this._diagnosticsSummary.failure]);
    }

    if (this._showExcluded) {
      this.chart.dataTable.push([
        'Excluded',
        this._diagnosticsSummary.excluded,
      ]);
      this.chart.options.slices = [
        { color: chartColours.finished },
        { color: chartColours.error },
        { color: chartColours.default },
      ];
    }

    this._chartTemplate?.draw(this.chart);
  }

  private _updateCompleteness(): void {
    const successes = this._diagnosticsSummary.success;

    // Handle undefined and 0
    if (!successes) {
      this.completeness = successes;
      return;
    }

    const failures = this._diagnosticsSummary.failure;
    const total = successes + failures;
    this.completeness = round((successes / total) * 100);
  }

  private get _isEmpty(): boolean {
    return (
      !this._diagnosticsSummary ||
      (!this._diagnosticsSummary.success &&
        !this._diagnosticsSummary.failure &&
        !this._diagnosticsSummary.excluded)
    );
  }
}
