import { Injectable } from '@angular/core';
import { WorkspacePersist } from '@models/persist';
import { EnumValues, PartialRecord } from '@utils';
import {
  TestPackGridAggregateSelection,
  TestPackGridSelection,
} from '../models/test-pack-grid-selection.model';
import { TransformType } from '../models/transform-config.model';

type StateMap = PartialRecord<
  EnumValues<typeof TransformType>,
  Record<keyof TestPackGridSelection, string>
>;

@Injectable()
export class TransformStateSyncService {
  private _transformState = new WorkspacePersist<StateMap>('transform-state', {
    default: {},
  });

  /*
  Set the pipeline and test pack selections for the transform type
  included in the given pipeline and if not already selected
  */
  setPipelines(
    pipelineSelection: TestPackGridSelection[] | TestPackGridAggregateSelection
  ): void {
    let selections: TestPackGridSelection[];

    if (Array.isArray(pipelineSelection)) {
      selections = pipelineSelection;
    } else {
      selections = [
        ...pipelineSelection.upstream,
        pipelineSelection.midstream,
        ...pipelineSelection.downstream,
      ];
    }

    if (this._alreadySelected(selections)) {
      return;
    }

    selections.forEach(({ pipelineDef, testPackDef }) => {
      const transformType = this._getTransformType(pipelineDef.transformType);
      this.set(transformType, 'pipelineDef', pipelineDef.id);
      this.set(transformType, 'testPackDef', testPackDef.id);
    });
  }

  set(
    type: TransformType,
    key: keyof TestPackGridSelection,
    value: string
  ): void {
    const transformType = this._getTransformType(type);
    this._transformState.value = {
      ...this._transformState.value,
      [transformType]: {
        ...this._transformState.value[transformType],
        [key]: value,
      },
    };
  }

  get(
    type: TransformType,
    key: keyof TestPackGridSelection
  ): string | undefined {
    const transformType = this._getTransformType(type);
    return this._transformState.value[transformType]?.[key];
  }

  reset(): void {
    this._transformState.value = {};
  }

  private _alreadySelected(
    pipelineSelection: TestPackGridSelection[]
  ): boolean {
    return pipelineSelection.every(({ pipelineDef, testPackDef }) => {
      const transformType = this._getTransformType(pipelineDef.transformType);
      const state = this._transformState.value[transformType];
      return (
        state &&
        state['pipelineDef'] === pipelineDef.id &&
        state['testPackDef'] === testPackDef.id
      );
    });
  }

  private _getTransformType(type: TransformType): TransformType {
    if (type === TransformType.LegacyIngest) {
      type = TransformType.Ingest;
    }
    return type;
  }
}
