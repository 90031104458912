import {
  AfterViewInit,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { RosettaStepOption, RosettaTourService } from './rosetta-tour.service';

@Directive({
  standalone: true,
  selector: '[appRosettaTourStart]',
})
export class RosettaTourStartDirective implements OnChanges, AfterViewInit {
  constructor(private _rosettaTourService: RosettaTourService) {}

  @Input({ required: true, alias: 'appRosettaTourStart' })
  steps: RosettaStepOption[];

  ngOnChanges({ steps }: SimpleChanges): void {
    if (steps.currentValue) {
      this._startTour(this.steps);
    }
  }

  ngAfterViewInit(): void {
    this._startTour(this.steps);
  }

  private _startTour(steps: RosettaStepOption[]): void {
    if (!steps?.length || this._rosettaTourService.hasStarted()) {
      return;
    }

    this._rosettaTourService.loadTourSteps(steps);
    this._rosettaTourService.start();
  }
}
