import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NewSample } from '../../models';
import { NewSampleCardComponent } from '../new-sample-card/new-sample-card.component';

@Component({
  standalone: true,
  selector: 'app-new-sample-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgScrollbarModule,
    MatButtonModule,
    FontsModule,
    NewSampleCardComponent,
  ],
  templateUrl: './new-sample-list.component.html',
  styleUrls: ['./new-sample-list.component.scss'],
})
export class NewSampleListComponent {
  @Input()
  set samples(samples: NewSample[]) {
    this._samples = samples;
    this.canClearAll = samples.filter(s => !s.uploaded)?.length > 0;
  }
  get samples(): NewSample[] {
    return this._samples;
  }

  @Input() selectedSample: NewSample | null;

  @Input({ transform: booleanAttribute })
  disabled = false;

  @Output()
  editSample = new EventEmitter<NewSample>();

  @Output()
  deleteSample = new EventEmitter<NewSample>();

  @Output()
  deleteAll = new EventEmitter<void>();

  canClearAll = false;

  private _samples: NewSample[];
}
