<app-rosetta-dialog [options]="dialogOptions">
  <div appForceLinkTargetBlank>
    <ng-scrollbar
      class="height-full"
      *appShowSpinner="releaseNotes$ | async as releaseNotes; flexCentre: true"
    >
      <section *ngFor="let releaseNote of releaseNotes" @inOutAnimation>
        <h4>
          {{ releaseNote.tag }}
          <app-text-badge class="theme-bg-color ml-16" *ngIf="releaseNote.new"
            >New</app-text-badge
          >
        </h4>
        <div appForceLinkTargetBlank [innerHTML]="releaseNote.bodyAsHtml"></div>
      </section>
    </ng-scrollbar>
  </div>
</app-rosetta-dialog>
