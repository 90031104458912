import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import {
  displayDateFormatter,
  distanceFromNowInWords,
  naturalCompare,
} from '@utils';

export const getValueToDisplay = (params: ICellRendererParams): string => {
  return (params.valueFormatted ? params.valueFormatted : params.value) ?? '';
};

export const displayDateInWords = (value: Date | number | string): string =>
  distanceFromNowInWords(value) ?? 'Never';

export const displayDate = (
  value: number | string | Date | null | undefined,
  formatStrings = 'mediumDate'
): string => displayDateFormatter(value || '', formatStrings) || 'Never';

export const comparatorFunc =
  <TData = any>(func: (data: TData) => string): ColDef['comparator'] =>
  (_a, _b, nodeA, nodeB) => {
    const nameA = func(nodeA.data);
    const nameB = func(nodeB.data);
    return naturalCompare(nameA, nameB);
  };
