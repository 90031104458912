import { HttpErrorResponse } from '@angular/common/http';
import { getServerErrorMessage } from '@features/auth/login/services/api-error-handler';
import { isServerError } from '@models';

export const getErrorMessage = (error: Error): string | null => {
  let errorMessage: string = null;

  if (isServerError(error)) {
    errorMessage = error.userMessage || 'Server error occurred.';
  } else if (error instanceof HttpErrorResponse) {
    errorMessage = getServerErrorMessage(error);
  } else if (error instanceof Error) {
    errorMessage = 'An error occurred.';
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else if (error && typeof error === 'object') {
    errorMessage = JSON.stringify(error);
  }

  return errorMessage;
};

// TODO: Create better utility methods to get error message and cause
export const getErrorCause = (error: Error): string | null => {
  let errorMessage: string = null;

  if (error instanceof HttpErrorResponse && error.status === 400) {
    errorMessage = error.error.cause;
  }

  return errorMessage;
};
