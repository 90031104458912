import { Signal, signal } from '@angular/core';
import { RosettaEditorModel } from '@shared/modules';
import { RosettaErrorMessage } from '@shared/modules/error-message/error-message.model';
import { TestPackGridSelection } from './test-pack-grid-selection.model';
import { TransientSample } from './transient-sample.model';
import { isEmptyString } from '@utils';

export enum SampleState {
  Initial = 'initial',
  Saving = 'saving',
  Saved = 'saved',
  Errored = 'errored',
  Invalid = 'invalid',
}

export class NewSample {
  constructor(
    name: string,
    private _model: RosettaEditorModel,
    private _selection: TestPackGridSelection,
    private _fileName?: string
  ) {
    this._nameSignal.set(name);
  }

  private _textModel: monaco.editor.ITextModel;
  private _errorReason?: RosettaErrorMessage;
  private _status = signal<SampleState>(SampleState.Initial);
  private _nameSignal = signal('');

  get selection(): TestPackGridSelection {
    return this._selection;
  }

  get status(): Signal<SampleState> {
    if (this._isInvalid()) {
      return signal(SampleState.Invalid).asReadonly();
    }
    return this._status.asReadonly();
  }

  get errorReason(): RosettaErrorMessage | undefined {
    if (this._isInvalid()) {
      return {
        title: 'Invalid Sample',
        summary:
          'Sample name is missing. Please provide both a name and content for the sample.',
      };
    }
    return this._errorReason;
  }

  get name(): string {
    return this._nameSignal();
  }
  set name(value: string) {
    this._nameSignal.set(value);
  }

  get fileName(): string | undefined {
    return this._fileName;
  }

  get uploaded(): boolean {
    return this._status() === SampleState.Saved;
  }

  get waitingForUpload(): boolean {
    return this._status() !== SampleState.Saved;
  }

  get modelContent(): string {
    return this._model.value;
  }

  set modelContent(value: string) {
    this._model.value = value;
  }

  set textModel(textModel: monaco.editor.ITextModel) {
    this._textModel = textModel;
    this._model.uri = textModel?.uri;
  }

  remove(): void {
    this._textModel?.dispose();
  }

  setError(erroredReason: RosettaErrorMessage): void {
    this._status.set(SampleState.Errored);
    this._errorReason = erroredReason;
  }

  updateStatus(updateStatus: Exclude<SampleState, SampleState.Errored>): void {
    this._status.set(updateStatus as SampleState);
    this._errorReason = null;
  }

  updateJson(value: string): void {
    this._model = {
      ...this._model,
      value,
    };
  }

  getModel(): RosettaEditorModel {
    return this._model;
  }

  getTransientSample(): TransientSample {
    return {
      testPackDef: this._selection.testPackDef,
      sample: {
        sampleDef: {
          id: this.name,
          name: this.name,
        },
        json: this._model.value,
      },
    };
  }

  private _isInvalid(): boolean {
    return isEmptyString(this.name);
  }
}
