<div
  class="sample-item__container"
  [appHoverClass]="hoverBackgroundClass()"
  [baseClass]="backgroundClass()"
  [disabled]="isSelected()"
  [ngClass]="{
    'success-color': sampleAdded(),
    'error-color': sampleErrored(),
    'theme-color-warn': sampleInvalid(),
    disabled: disabled(),
    'selected theme-color-primary font-weight-bold': isSelected(),
  }"
>
  <div class="status-tab"></div>

  <ng-template #invalidStatus>
    <fa-icon class="sample-item__icon" icon="triangle-exclamation" size="xl" />
  </ng-template>

  <fa-icon
    *ngIf="!sampleInvalid(); else invalidStatus"
    class="sample-item__icon"
    [icon]="sample.fileName ? 'file-arrow-up' : 'file-pen'"
    size="xl"
  />

  <div class="sample-item__details spacer">
    <p class="mb-0 font-weight-bold text-ellipsis theme-color-75">
      {{ sample.name || '-' }}
    </p>
    <small class="color-50">{{ sample.fileName ?? 'Created' }} </small>
  </div>

  <div class="sample-item__status" *ngIf="statusIcon(); let statusIcon">
    <small *ngIf="statusText(); let statusText" class="mr-8">{{
      statusText
    }}</small>
    <fa-icon class="status-icon" [icon]="statusIcon" size="lg" />
  </div>

  <div class="sample-item__actions">
    <app-more-options-menu
      *ngIf="!disabled()"
      appStopPropagation
      [menuItems]="actionMenuItems"
    />
  </div>
</div>
