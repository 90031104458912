import { NgModule } from '@angular/core';
import { RosettaOverlayHoverTargetDirective } from './rosetta-overlay-hover-target.directive';
import { RosettaOverlayTargetDirective } from './rosetta-overlay-target.directive';
import { RosettaOverlayComponent } from './rosetta-overlay.component';

@NgModule({
  imports: [
    RosettaOverlayComponent,
    RosettaOverlayTargetDirective,
    RosettaOverlayHoverTargetDirective,
  ],
  exports: [
    RosettaOverlayComponent,
    RosettaOverlayHoverTargetDirective,
    RosettaOverlayTargetDirective,
  ],
})
export class RosettaOverlayModule {}
