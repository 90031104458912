<app-rosetta-code-editor
  *ngIf="hideNative"
  [editorOptions]="editorOptions"
  [options]="options"
  [model]="{
    value: codeView?.nativeCode,
    language,
  }"
/>
<div [hidden]="hideNative">
  <header>
    <button
      mat-icon-button
      (click)="treeControl.collapseAll()"
      [disabled]="!codeView?.codeViewNode"
    >
      <fa-icon icon="minus-square" [fixedWidth]="true" />
    </button>
    <button
      mat-icon-button
      (click)="treeControl.expandAll()"
      [disabled]="!codeView?.codeViewNode"
    >
      <fa-icon icon="plus-square" [fixedWidth]="true" />
    </button>
    <div class="spacer"></div>
    <app-code-view-search (changes)="scrollTo($event)" />
  </header>

  <ng-scrollbar
    track="all"
    [appScrollPosition]="name"
    *ngrxLet="selectedNodeId$ | async as selectedNodeId"
  >
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      [appPersistExpandState]="name"
    >
      <!-- LEAF-NODE-START -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        class="mat-tree-node"
        [ngClass]="node.nodeClass"
        [class.selected]="node.id === selectedNodeId"
        (click)="selectNode(node.id)"
      >
        <div matTreeNodePadding matTreeNodePaddingIndent="20">
          <button mat-icon-button disabled></button>

          <span class="node-name" [ngClass]="node.nodeClass">
            {{ node?.name }}
          </span>
          <fa-icon
            class="opacity-50"
            icon="circle-exclamation"
            *ngIf="node.errors.length > 0"
            [appRosettaOverlayHoverTarget]="overlay"
          />
          <app-rosetta-overlay #overlay>
            <app-rosetta-menu maxWidth="450">
              <p *ngFor="let error of node.errors">{{ error }}</p>
            </app-rosetta-menu>
          </app-rosetta-overlay>
        </div>

        <div
          [id]="node?.id"
          #nodeId
          class="node-value"
          [ngClass]="node.nodeClass"
        >
          <span
            *ngIf="node.linkId; else nodeValue"
            appStopPropagation
            (click)="onLinkId(node)"
            >{{ node?.value }}</span
          >
          <ng-template #nodeValue>{{ node?.value }}</ng-template>
        </div>
      </mat-tree-node>
      <!-- LEAF-NODE-END -->

      <!-- NODE-START -->
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        class="group-node"
        [ngClass]="node.nodeClass"
        [class.selected]="node.id === selectedNodeId"
        (click)="selectNode(node.id)"
      >
        <div matTreeNodePadding matTreeNodePaddingIndent="20">
          <button
            matTreeNodeToggle
            mat-icon-button
            [attr.aria-label]="'toggle ' + node?.name"
          >
            <fa-icon
              [icon]="[
                'fas',
                treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right',
              ]"
            />
          </button>

          <span class="node-name" [ngClass]="node.childClass">
            {{ node?.name }}
          </span>
          <fa-icon
            class="opacity-50"
            icon="circle-exclamation"
            *ngIf="node.errors.length > 0"
            [appRosettaOverlayHoverTarget]="overlay"
          />
          <app-rosetta-overlay #overlay>
            <app-rosetta-menu maxWidth="450">
              <p *ngFor="let error of node.errors">{{ error }}</p>
            </app-rosetta-menu>
          </app-rosetta-overlay>
        </div>

        <div
          [id]="node?.id"
          #nodeId
          class="node-value"
          [ngClass]="node.nodeClass"
        >
          <span
            *ngIf="node.linkId; else nodeValue"
            appStopPropagation
            (click)="onLinkId(node)"
            >{{ node?.value }}</span
          >
          <ng-template #nodeValue>{{ node?.value }}</ng-template>
        </div>
      </mat-tree-node>
      <!-- NODE-END -->
    </mat-tree>
  </ng-scrollbar>

  <app-code-view-selected
    *ngIf="showSelectedCodeView"
    [node]="selectedNode$ | async"
    [modelContent]="modelContent"
  />
</div>
